/* _form-check.scss */

// FIX ME associare la classe is-invalid all'input e dare il feedback sia all'input che alla span (fatto solo per la classe invalid)

.form-check-custom {
  display: flex;
  align-items: center;
  height: map-get($checkbox, dimention);
  position: relative;
  padding-left: calc(#{map-get($checkbox, dimention)} + 0.625rem);
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 0.88rem;
  user-select: none;

  input {

    &.invalid,
    &.is-invalid {
      ~.form-check-custom {

        &__check,
        &__radio {
          color: setColor('semantic', 'error');
        }

        &__label {
          color: setColor('semantic', 'error');
        }
      }
    }
  }

  &.disabled,
  &.is-disabled {
    opacity: 0.8;
    pointer-events: none;

    .form-check-custom {

      &__check,
      &__radio {
        opacity: 0.8;
        pointer-events: none;
        color: setColor('theme', 't01') !important;
      }
    }

    input {
      &:checked {
        ~.form-check-custom {

          &__check,
          &__radio {
            color: setColor('theme', 't01');

            &:after {
              color: setColor('theme', 't01');
            }
          }

          &__radio {
            &:after {
              color: setColor('theme', 't01');
            }
          }
        }
      }
    }
  }

  &.invalid,
  &.is-invalid {
    color: setColor('semantic', 'error');

    .form-check-custom {

      &__check,
      &__radio {
        color: setColor('semantic', 'error');
      }
    }

    input {
      &:checked {
        ~.form-check-custom {

          &__check,
          &__radio {
            color: setColor('semantic', 'error');

            &:after {
              color: setColor('semantic', 'error');
            }
          }

          &__radio {
            &:after {
              color: setColor('semantic', 'error');
            }
          }
        }
      }
    }
  }

  &.valid,
  &.is-valid {
    color: #fff;

    .form-check-custom {

      &__check,
      &__radio {
        border-color: setColor('semantic', 'success');
      }
    }

    input {
      &:checked {
        ~.form-check-custom {

          &__check,
          &__radio {
            color: setColor('semantic', 'success');

            &:after {
              color: setColor('semantic', 'success');
            }
          }

          &__radio {
            &:after {
              color: setColor('semantic', 'success');
            }
          }
        }
      }
    }
  }

  &.warning,
  &.is-warning {
    color: setColor('semantic', 'warning');

    .form-check-custom {

      &__check,
      &__radio {
        color: setColor('semantic', 'warning');
      }
    }

    input {
      &:checked {
        ~.form-check-custom {

          &__check,
          &__radio {
            color: setColor('semantic', 'warning');

            &:after {
              color: setColor('semantic', 'warning');
            }
          }

          &__radio {
            &:after {
              color: setColor('semantic', 'warning');
            }
          }
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~.form-check-custom {
        &__check {
          color: setColor('theme', 't01');

          &:after {
            content: '\e909';
          }
        }

        &__radio {
          color: setColor('theme', 't01');

          &:after {
            content: '\e924';
          }
        }
      }
    }
  }

  &__check {
    position: absolute;
    top: -3px;
    left: 0;
    font-size: 1.25rem;

    &:after {
      font-family: 'oto-icons-mini' !important;
      content: '\e908';
      color: setColor('theme', 't01');
      display: block;
    }
  }

  &__radio {
    position: absolute;
    top: -3px;
    left: 0;
    font-size: 1.25rem;

    &:after {
      font-family: 'oto-icons-mini' !important;
      content: '\e923';
      color: setColor('theme', 't01');
      display: block;
    }
  }

  &__label {
    font-size: 1rem;
    font-weight: 700;
    padding-left: 2rem;
  }

  &__logo {
    position: absolute;
    right: .5rem;
    width: 2rem;
  }

}

.form-check-big {
  height: auto;
  padding: 0;

  &__label {
    font-size: 1.375rem;
    color: setColor('theme', 't03');
    font-weight: 700;
    background-color: #EBF9FF;
    border-radius: 5px;
    padding: 20px 60px 20px;
    position: relative;
    cursor: pointer;
    width: 100%;
  }

  &__price {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    font-size: 1.5rem;
    color: setColor('theme', 't03');
    font-weight: 700;

    span {
      font-size: 1rem;
      color: #000;
      font-weight: 400;
    }
  }

  &__agency {
    display: flex;
    max-height: 100px;

    span {
      font-size: 1.5rem;
      color: setColor('theme', 't03');
      font-weight: 700;
    }

    &__data {
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      padding: 0 20px;

      .form-check-big__caption {
        width: 200px;
        padding: 5px;
      }
    }
  }

  &__caption {
    font-size: .875rem;
    line-height: 1.7;
    color: #000;
  }

  &__radio {
    top: 1.5rem;
    left: 1.5rem;
  }
}

/* _form-check.scss END */
