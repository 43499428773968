.c-booking {
  display: flex;
  flex-flow: column;

  &__intro {
    h1 {
      font-size: 1.65rem;
    }
  }

  &__section {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 2.5rem;
      padding-bottom: 2.5rem;

      &::after  {
        content: '';
        position: absolute;
        bottom: 0;
        height: 2px;
        left: 0;
        right: 0;
        background-image: linear-gradient(to right, #D9E9EF 55%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-size: 20px 2px;
        background-repeat: repeat-x;
      }
    }

  }

  &__last-pic {
    width: 100%;
  }

  .row-input {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    .form-group-custom {
      flex: 1 0;
      max-width: 300px;
    }
  }
}
