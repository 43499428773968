@import "src/global/styles/configurations";

.o-holiday-search {

  &__engine {
    display: none;
    box-shadow: 0 20px 20px 6px rgba(0, 0, 0, 0.4);
    background-color: setColor('neutral', 'n01');
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    margin-bottom: 2rem;
    @media (max-width: map-get($grid-breakpoints, lg)) {
      z-index: 10;
      // padding-top: 2rem;
    }
    @media (min-width: map-get($grid-breakpoints, lg)) {
      position: relative;
    }
    &.show-engine {
      display: block;
      box-shadow: none;
      @media (min-width: map-get($grid-breakpoints, lg)) {
        position: relative;
        top: 0;
      }
      .o-engine {
        margin-top: 0;
      }
    }
  }
  &__edit {
    margin-bottom: 1.5rem;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-wrap: nowrap;
    }
  }
  &__aside {
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 0 0 30%;
      max-width: 30%;
      padding: 0 1rem 0 0;
      margin-bottom: 3rem;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  &__results {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 1 0 70%;
      padding: 0 1.5rem 0 0;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
      flex: 1 0 75%;
      padding: 0 1.5rem 0 0;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
      padding: 0 2rem 0 0;
    }
  }

}
