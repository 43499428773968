@import "src/global/styles/configurations";

.c-chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 11px;
  color: setColor('theme', 't01');
  border: 1px solid setColor('theme', 't01');
  text-align: center;
  padding: .35rem .75rem;

  &--accent {
    background-color: setColor('theme', 't02');
    border-color: setColor('theme', 't02');
    border-radius: .25rem;
    color: setColor('neutral', 'n01');
  }
  &__ico {
    font-size: 1rem;
    margin-right: .5rem;
  }
}
