.c-gazzetta{
  height: 100%;
  background-color: #fde9eb;

  &__header{
      height: 115px;

      .logo__wrapper--me{
          width: 230px;
          display: block;



              img{
                  object-fit: contain;
                  width: 100%;
                  background-position: center;
                  display: block;
              }

      }

      .logo__wrapper--gazzetta{
          width: 200px;
          display: block;

              img{
                  object-fit: contain;
                  width: 100%;
                  background-position: center;
                  display: block;
              }
      }

      .c-gazzetta__subtitle{
          font-style: italic;
          font-size: 12px;
      }
  }

  &__jumbotron{
      height: 450px;
      width: 100%;

      .jumbotron__wrapper{
          width: 100%;

          img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              background-position: center;
              display: block;
          };
      }
  }

  &__editorial{
      .gazzetta__badge{
          width: 100%;
          max-width: 400px;
          display: block;

          img{
              width: 100%;
              object-fit: contain;
              object-position: center;
              display: block;
          }
      }

      .gazzetta_wrapper__video{
          display: block;
          width: 100%;
          border: 5px solid white;
          border-radius: 0.8rem;
          overflow: hidden;
          box-shadow: 0px 0px 15px -5px #131313;

          img{
              width: 100%;
              object-fit: contain;
              object-position: center;
          }
      }
  }

  &__products{

      .card-gazzetta{
          position: relative;

          &::after{
              border-bottom: 8px solid #e3bfbf;
              display: block;
              content: '';
              margin-top: 1.2rem;
          }

          &:first-child:before{
              border-top: 8px solid #e3bfbf;
              display: block;
              content: '';
              margin-bottom: 1.2rem;
          }

          .tax{
              text-align: right;
          }




          .card-image__wrapper{
              width: 100%;
              display: block;
              border-radius: 0.4rem;
              border: 2px solid white;
              overflow: hidden;

                  img{
                      width: 100%;
                      object-fit: cover;
                      object-position: center;
                  }
          }
      }

  }

  &__sponsor{
      .sponsor-logo__wrapper{
          width: 200px;
          display: block;
          margin: 0.6rem 0.4rem;

              img{
                  width: 100%;
                  max-height: 40px;
                  object-fit: contain;
                  object-position: center;
                  display: block;
              }
      }
  }

  &__newsletter{

      .newsletter{
          border-radius: 0.8rem;
          overflow: hidden;
          background-image: url("../../../assets/images/placeholder_newsletter.png");
          background-size: cover;
          background-position: center;
          position: relative;

          &::before{
              position: absolute;
              content: '';
              display: block;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background: rgb(0,91,154);
              background: linear-gradient(90deg, rgba(0,91,154,1) 50%, rgba(255,255,255,0) 100%);
          }
      }
  }


}

//Utilities

.fs{

  &-10{
      font-size: 10px !important;
  }
  &-12{
      font-size: 12px !important;
  }
  &-14{
      font-size: 14px !important;
  }
  &-20{
      font-size: 20px !important;
  }

}

.fw{
  &-400{
      font-weight: 400;
  }
  &-500{
      font-weight: 500;
  }
  &-600{
      font-weight: 600;
  }
  &-700{
      font-weight: 700;
  }
  &-800{
      font-weight: 800;
  }
}

.z{
&-1{
  z-index: 1;
}
&-2{
  z-index: 2;
}
&-3{
  z-index: 3;
}
}
