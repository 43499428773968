@import "src/global/styles/configurations";

.o-destinations-thumbnail {
  &__cta-back {
    position: absolute;
    top: -1rem;
    left: 0;
    @media (min-width: 992px) {
      top: -1.5rem;
      left: 1rem;
    }
    button {
      padding: 0.5rem 1.5rem;
      border: 0;
      &.btn--xs {
        font-size: 1.2rem;
        @media (min-width: 992px) {
          font-size: .75rem;
        }
      }
    }
  }
}
