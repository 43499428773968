.c-card-insurance {
  display: flex;
  flex-flow: column;
  padding: 1rem;
  background: #FFF;
  border: 1px solid setColor('ui', 'border-color');
  box-shadow: map-get($card, box-shadow);
  border-radius: 1rem;

  @media (min-width: 992px) {
    flex-flow: row;
    padding: 1.5rem;
  }

  &__col {
    display: flex;
    flex-flow: column;
    flex: 1 0 100%;

    @media (min-width: 992px) {
      flex: 0 0 50%;
    }

    &--small {
      flex: 1 0 100%;

      @media (min-width: 992px) {
        flex: 0 0 30%;
      }
    }

    &--big {
      flex: 1 0 100%;

      @media (min-width: 992px) {
        flex: 0 0 70%;
      }
    }

    &--prices {
      text-align: right;
      align-self: flex-end;
    }

    .form-check-custom {
      margin-bottom: .25rem;
    }

  }

  &__price {
    font-weight: 700;
    font-size: 1.2rem;
    color: setColor('ui', 'text-dark');
    text-align: right;
  }

  &__show-more,
  &__text {
    font-size: .875rem;
    margin-left: 2rem;
  }

  &__text {
    color: setColor('ui', 'text-placeholder');
  }

}
