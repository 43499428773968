.c-ancillary-row {
  display: flex;
  flex-flow: column;
  padding: 1rem;

  @media (min-width: 992px) {
    flex-flow: row;
    padding: 1.5rem;
  }

  &__col {
    display: flex;
    flex-flow: column;
    flex: 1 0 100%;

    @media (min-width: 992px) {
      flex: 1 0 50%;
    }

    &--prices {
      text-align: right;
      align-self: flex-end;

      @media (min-width: 992px) {
        flex: 0 0 25%;
      }
    }

    .form-check-custom {
      margin-bottom: .25rem;
      &__label {
        padding-left: 2rem;
      }
    }

  }
  &__price {
    font-weight: 700;
    font-size: 1.125rem;
    color: setColor('ui', 'text-dark');
    text-align: right;
  }

  &__show-more {
    font-size: .875rem;
    margin-left: 2rem;
  }
}
