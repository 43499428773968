.u-dashed-border {
  position: relative;
  border-color: transparent;
  &::before, &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to right, #ddd 50%, transparent 50%);
    background-size: 15px;
    z-index: 0;
  }
}
