.c-recap {
  position: static;
  bottom: 0;
  border-radius: 1rem;
  padding: 2rem;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  background-color: setColor('theme', 't01');
  color: #fff;
  z-index: 2;
  @media (min-width: 992px) {
    position: relative;
  }

  @media (min-width: 992px) {
    position: sticky;
  }

  &-mobile {
    background-color: setColor('theme', 't01');
    z-index: 3;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) {
      display: none;
    }
    &__title {
      flex: 0 0 72%;
      @include multiLineEllipsis($lineHeight: 1.2em, $lineCount: 2, $bgColor: transparent);
    }
    &__price {
      flex: 0 0 28%;
      font-size: 1.3rem;
      font-weight: 700;
      align-self: flex-end;
      text-align: right;
    }
  }

  &-info {
    display: block;
  }

  &__icon {
    position: absolute;
    font-size: 1.5rem;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    color: #fff;
    background-color: setColor('theme', 't01');
    top: -1.7rem;
    right: 1rem;
    line-height: 2;
    z-index: 0;
    text-align: center;
    cursor: pointer;
    @media (min-width: 992px) {
      display: none;
    }
  }

  &__header {
    display: flex;
    flex-flow: column;
    gap: 1rem;

    &-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    &-resume {
      color: setColor('theme', 't02');
      font-weight: 700;
      font-size: 18px;
    }

    &-to {
      height: 34px;
      width: 100px;
      background-color: #fff;
      border-radius: 1rem 0 0 1rem;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;

      img {
        width: 100px;
        padding: .25rem;
        object-fit: contain;
        height: 28px;
      }
    }

    &-title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.1;
      color: #FFF;
      margin-bottom: 2rem;

      [class^="icon-"],
      [class*=" icon-"] {
        color: #EABA00;
      }
    }

  }

  &__body {
    display: flex;
    flex-flow: column;
    margin-bottom: 1.5rem;

    +.c-recap__body {
      margin-bottom: 0;
    }

    &-row {
      display: flex;
      flex-flow: row;
      ul {
        list-style: none;
        padding: 0;
        li {
          margin-bottom: .75rem;
        }
      }
    }

    &-col {
      flex: 0 0 50%;
    }

  }

  &__main-info {
    display: flex;
    align-items: baseline;

    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: .5rem;
    }

    &-label {
      font-weight: 500;
      opacity: .7;
      text-transform: lowercase;
      display: inline-block;
      margin-left: .5rem;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  &__departure {
    display: flex;
    flex-flow: column;

    &-label {
      text-transform: uppercase;
      opacity: 0.75;
      font-size: 13px;
      color: #FFF;
      display: block;
      margin-bottom: .75rem;
    }

    &-point {
      font-weight: 500;
      font-size: 1rem;
      color: #FFF;
    }
  }

  &__bill-info {
    line-height: 1.2;
    list-style: none;
    border-top: 1px dashed setColor('ui', 'border-color');
    padding: 1.25rem 0;
    margin: 1.25rem 0 0;

    &-col {
      display: flex;
      flex-flow: column;
      flex: 0 0 50%;
      max-width: 50%;

      &--small {
        flex: 0 0 30%;
        max-width: 30%;
      }

      &--big {
        flex: 0 0 70%;
        max-width: 70%;
      }

      small {
        margin-top: .5rem;
        font-size: .875rem;
        opacity: .75;
      }
    }

    &-item {
      display: flex;
      align-items: baseline;
      border-bottom: 1px solid rgba(255, 255, 255, .3);

      &__multiple {
        display: flex;
        width: 100%;
      }

      &:nth-last-child(-n+2) {
        border-bottom: 1px dashed #fff;
      }

      &:not(:last-of-type) {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }

      &--total {
        padding-bottom: 1rem;

        span {
          font-weight: 500;
          font-size: 1.25rem;
          color: #FFF;
          letter-spacing: 0;

          &.c-recap__bill-info-price {
            font-size: 28px;
          }
        }
      }

    }

    &-price {
      font-weight: 500;
      text-align: right;
    }

  }

  &__footer {
    display: flex;
    flex-flow: column;
    gap: 1rem;

    .c-button {
      width: 100%;
    }

    p {
      line-height: 1.2;
    }
  }

  &__code {
    font-size: 13px;
    text-align: center;
  }

  .icon-trash {
    opacity: .6;
    transition: opacity .22s;
    cursor: pointer;
    margin-right: .5rem;

    &:hover {
      opacity: 1;
      transition: opacity .33s;
    }
  }

}

.c-recap-button {
  margin-left: 0.25rem !important;
}

@media (min-width: 350px) {
  .c-recap-button {
      margin-left: 1.5rem !important;
  }
}
