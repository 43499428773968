.o-booking {
  padding: 2rem .5rem;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  position: relative;

  @media (min-width: 992px) {
    flex-flow: row;
    gap: 2rem;
    padding: 2rem 0;
    align-items: flex-start;
  }

  &__skip {
    position: absolute;
    top: 4px;
    right: 0;
    color: $brand-color-01;

    span {
      font-size: 10px;
    }
  }

  &__body {
    flex: 1 0;

    @media (min-width: 992px) {
      flex: 1 0 calc(60% - 1rem);
      max-width: calc(60% - 1rem);
    }
  }

  &__aside {
    flex: 1 0;
    margin: 1rem 0;

    @media (min-width: 992px) {
      margin: 0;
      position: sticky;
      top: 0;
      bottom: auto;
      flex: 0 0 calc(40% - .5rem);
      max-width: calc(40% - .5rem);
    }
  }

}
