.c-section-step {
  padding-left: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    border-left: 1px solid setColor('ui', 'border-color');
  }
  &::before {
    position: absolute;
    content: '';
    top: 1px;
    left: -.35rem;
    height: 13px;
    width: 13px;
    border-radius: 100%;
    background-color: setColor('theme', 't02');
  }
  &__title {
    text-transform: uppercase;
  }
  &__body {
    margin-bottom: 4rem;
  }
}
