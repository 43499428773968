@import "configurations";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap');

html,
body {
  font-family: $body-font-primary;
  font-size: 16px;
  color: setColor('dark', 'darken-80');
  line-height: 1.2;
  background-color: setColor('ui', 'background');
}

h1,
h1.h1,
h2,
h2.h2,
h3,
h3.h3,
h4,
h4.h4,
h5,
h5.h5 {
  font: normal map-get($font-weights, "bold") 1rem/1.2 $body-font-primary;
  color: setColor('dark', 'darken-80');
  margin-bottom: map-get($spacers, 1);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    font: normal map-get($font-weights, "bold") 1rem/1.1 $body-font-primary;
  }
}

h1,
h1.h1 {
  font-size: 2.25rem; // 36px
  line-height: 1.25;
  font-weight: 700;
  @media (min-width: map-get($grid-breakpoints, sm)) {

  }
}

h2,
h2.h2 {
  font-size: 1.75rem; // 28px
  font-weight: 700;
  @media (min-width: map-get($grid-breakpoints, sm)) {

  }
}

h3,
h3.h3 {
  font-size: 1.25rem; // 20px
  line-height: 1.6;
  font-weight: 700;
  @media (min-width: map-get($grid-breakpoints, sm)) {

  }
}

h4,
h4.h4 {
  font-size: 1.125rem;
  font-weight: 600;
  @media (min-width: map-get($grid-breakpoints, sm)) {

  }
}

h5,
h5.h5 {
  font-size: 1rem;
}

p {
  font: normal map-get($font-weights, "normal") 1rem/1.5 $body-font-primary;
  letter-spacing: normal;
  color: setColor('dark', 'darken-60');
  margin-bottom: map-get($spacers, 1);
}

small {
  font: normal map-get($font-weights, "normal") .8125rem/normal $body-font-primary;
  letter-spacing: normal;
  color: setColor('dark', 'darken-80');
}
