.c-card-registry {
  display: flex;
  flex-flow: column;
  background-color: #fff;
  border: 1px solid setColor('ui', 'border-color');
  border-radius: 1rem;
  padding: 1.25rem;
  box-shadow: map-get($card, box-shadow);

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: .875rem;
  }

  &__holder {
    background-color: setColor('theme', 't01');
    color: setColor('neutral', 'n01');
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0;
    text-align: center;
    padding: .25rem 2rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__row {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }
  &__col {
    flex: 1 0 100%;
    @media (min-width: 992px) {
      flex: 1 0;
    }
    &--small {
      flex: 1 0 100%;;
      @media (min-width: 992px) {
        flex: 0 0 calc(22% - .5rem);
        max-width: calc(22% - .5rem);
      }
    }
    &--medium {
      flex: 1 0 100%;;
      @media (min-width: 992px) {
        flex: 0 0 calc(35% - .5rem);
        max-width: calc(35% - .5rem);
      }
    }
    .form-group-custom {
      &__feedback {
        font-size: .8125rem;
        line-height: 1;
        .alert {
          margin-bottom: 0;
          padding: .5rem;
        }
      }
    }
  }

  &__name {
    margin-top: 2.5rem;
  }

}
