:root {
  --m-height: 360px;
  --d-height: 200px;
  --a-height: unset;
  --e-height: 180px;
  --p-height: 440px;
  --i-height: 120px;
  --s-height: 286px;
  --b-height: 229px;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  :root {
    --b-height: 229px;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  :root {
    --b-height: 286px;
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  :root {
    --b-height: 206px;
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  :root {
    --b-height: 286px;
  }
}

.c-card {

  display: flex;
  border-radius: map-get($card, border-radius);
  box-shadow: map-get($card, box-shadow);
  user-select: none;
  padding: 1rem;
  flex-direction: column;
  background-color: setColor('neutral', 'n01');
  margin-bottom: map-get($gutters, 4);

  // Per prodotto custom
  &--featured {
    border: 3px solid #FBA610;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    flex-direction: row;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  .c-card {
    &__pic {
      flex: 0 0 50%;
      width: 100%;
      border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
      overflow: hidden;
      position: relative;

      &:before {
        border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
      }

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex: 0 0 auto;
        width: 33.33333333%;
        border-radius: map-get($card, border-radius);

        &:before {
          border-radius: map-get($card, border-radius);
        }
      }

      @media (min-width: 1600px) {
        width: 25%;
      }

      img {
        border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
        object-fit: cover;
        object-position: center;
        width: 100%;

        &:before {
          border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
        }

        @media (min-width: map-get($grid-breakpoints, md)) {
          border-radius: map-get($card, border-radius);
          aspect-ratio: 1;

          &:before {
            border-radius: map-get($card, border-radius);
          }
        }
      }
    }

    &__logo-to {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 100%;
      position: absolute;
      top: 1rem;
      left: 1rem;
      padding: .25rem;
      overflow: hidden;
      z-index: 1;
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.30));
      &.no-shadow {
        filter: none;
      }
      &.no-background {
        background-color: transparent;
      }

        img{
          object-fit: contain;
        }
    }
    &__body {
      flex: 0 0 50%;
      width: 100%;
      height: 50%;
      padding-top: 1rem;

      @media (min-width: map-get($grid-breakpoints, md)) {
        flex: 0 0 66.66666666%;
        width: 66.66666666%;
        height: auto;
        border-radius: 0 map-get($card, border-radius) map-get($card, border-radius) 0;
        display: flex;
        flex-flow: row wrap;
        padding-top: 0;
        padding-left: 1rem;
        justify-content: space-between;

        h5.h5 {
          @include multiLineEllipsis($lineHeight: 1.5em, $lineCount: 2, $bgColor: transparent);
          margin-bottom: 0;
        }
      }

      @media (min-width: map-get($grid-breakpoints, xl)) {
        flex: 1 0 66.66666666%;
        width: 66.66666666%;
        flex-wrap: nowrap;
      }

      @media (min-width: 1600px) {
        width: 75%;
      }
    }

    .c-card-label {
      display: block;
      font-size: .875rem;
      padding: 0.4rem 1.5rem .4rem 0;
      position: relative;
      .icon-date {
        + span {
          font-weight: 600;
        }
      }
      i {
        font-size: 1rem;
        display: inline-block;
        padding-right: .3rem;
        color: $brand-color-02;
        &:before {
          font-weight: 600;
        }
        &.icon-arrow-thick-right, &.icon-arrow-thick-left {
          font-size: .75rem;
          padding-right: .2rem;
        }
        &.icon-check-out {
          border-left: 1px solid #888;
          margin-left: .5rem;
          padding-left: .5rem;
        }
        + span {
          + i {
            border-left: 2px solid #ccc;
            padding-left: .5rem;
            margin-left: .5rem;
          }
        }
      }
      &--inline {
        display: inline-block;
      }
      a {
        color: $brand-color-02;
        text-decoration: underline;
      }
    }
    .c-card-departures {
      display: flex;
      flex-direction: row;
      padding: .8rem 0;
      margin: 1rem 0;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid #D9D9D9;
      border-bottom: 1px solid #D9D9D9;
      @media (min-width: 1600px) {
        justify-content: center;
      }
      &--no-border {
        border: 0;
        padding: 0;
      }
      &__label {
        display: block;
        color: $brand-color-04;
        font-weight: 600;
        text-align: center;
      }
      &__item {
        margin: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        i {
          font-size: 1.2rem;
          color: $brand-color-02;
        }
      }
      &__item-packet {
        margin: 0 0rem;
        display: flex;
        align-items: center;
        justify-content: left;
        i {
          font-size: 1.2rem;
          color: $brand-color-02;
        }
      }
      &__detail {
        padding-left: .7rem;
        font-size: .875rem;
        position: relative;
        span, hr {
          display: block;
          padding-right: .5rem;
          @media (min-width: 1600px) {
            display: inline-block;
          }
        }
        hr {
          height: 20px;
          border-left: 2px solid #EF8B19;
          margin: 4px auto;
          border-top: 0;
          width: 2px;
          @media (min-width: 1600px) {
            width: 20px;
            border-top: 2px solid #EF8B19;
            margin: 4px 0;
            border-left: 0;
            height: unset;
          }
        }
      }
    }
    &__labels {
      margin-bottom: 1rem;
    }
    &__destination {
      font-size: .875rem;
      opacity: .6;
      position: relative;
    }
    &__main-info {
      flex: auto;
      padding-right: 1rem;
    }
    &__sub-info {
      display: flex;
      flex-direction: column;
      text-align: right;
      align-items: flex-end;
      flex: 1 0 30%;
    }
    &__adv {
      flex: 1;
      padding: .25rem;
      display: flex;
      align-items: end;
      img {
        max-width: 65px;
      }
    }
    &__prices {
      flex: 0 0 70%;
      max-width: 70%;
      span {
        display: block;
        font-size: .7125rem;
      }
      small {
        opacity: .6;
        font-size: .7125rem;
        line-height: 17px;
        display: block;
      }
    }
    &__value {
      font-size: 1.125rem !important;
      color: setColor('neutral', 'n02');
      font-weight: 700;
      margin-bottom: .25rem;
      position: relative;
      span {
        font-size: .9125rem;
        display: inline-block;
      }
    }
    &__cta {
      margin-top: auto;
      position: relative;
    }

  }

  &--vertical {
    flex-direction: column;
    padding: 0;

    .c-card {
      &__pic {
        //flex: unset;
        width: 100%;
        height: 191px;
        border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
        overflow: hidden;

        &:before {
          border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
        }

        img {
          border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
          aspect-ratio: unset;

          &:before {
            border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
          }

        }
      }

      &__body {
        flex: 0 0 50%;
        width: 100%;
        border-radius: 0 0 map-get($card, border-radius) map-get($card, border-radius);
        padding: 1rem;
        flex-direction: column;

        h5.h5 {
          @include multiLineEllipsis($lineHeight: 1.5em, $lineCount: 2, $bgColor: transparent);
          color: setColor('dark', 'darken-80');
          margin-bottom: .5rem;
          height: 48px;
        }

      }

      &__main-info {
        padding-right: 0;
      }

      &__detail {
        span {
          opacity: .65;
          font-size: .9125rem;
        }
      }

      &__sub-info {
        flex-direction: row;
      }

      &__tag {
        text-align: right;
        span {
          display: inline-block;
          border: 1px solid #2383ac;
          padding: .35rem .5rem;
          color: #2383ac;
          font-size: .65rem;
          font-weight: 600;
          margin: .35rem 0;
          border-radius: 2px;
        }
      }

      &__pp {
        color: setColor('dark', 'darken-60');
      }

    }
  }

  &--solution-detail {
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
    border: 1px solid setColor('ui', 'border-color');
    border-radius: .5rem;

    @media (min-width: 1200px) {
      flex-direction: row;
    }

    &__info {
      flex: 1 0 100%;
      width: 100%;
      border-bottom: 1px solid setColor('ui', 'border-color');

      @media (min-width: 992px) {
        flex: 1 0;
      }

      @media (min-width: 1200px) {
        max-width: 340px;
        border-bottom: 0;
        border-right: 1px solid setColor('ui', 'border-color');
      }

      @media (min-width: 1600px) {
        max-width: 370px;
      }

      picture {
        position: relative;
        height: 200px;
        display: block;
      }
    }

    &__solution {
      flex: 1 0 100%;
      width: 100%;

      @media (min-width: 1200px) {
        flex: 1 0;
        // border-left: 1px solid $ui-border-color;
      }
    }

    &__pic {
      height: 200px;
      width: 100%;
      border-radius: .5rem 0 0 0;
      object-fit: cover;
    }

    &__pic-banner {
      padding: .25rem 1rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: .875rem;
      font-weight: 600;
      text-decoration: underline !important;

      &--green {
        color: #fff !important;
        background: #00A652;
      }
    }

    &__content {
      font-size: 14px;
      padding: 1rem;
      display: flex;
      flex-flow: column;
      align-items: start;
      gap: 1rem;
      user-select: none;
      position: sticky;
      top: 0;
      bottom: auto;

      @media (min-width: 992px) {
        padding: 1.5rem;
      }
    }

    &__days {
      border: 1px solid setColor('ui', 'border-color');
      padding: .25rem .5rem;
      color: setColor('ui', 'text-placeholder');
    }

    &__title {
      font-weight: 700;
      font-size: 20px;
      color: setColor('ui', 'text-dark');
      letter-spacing: 0;
      text-transform: lowercase;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    &__dates {
      display: flex;

      &-ico {
        margin-right: .5rem;
        padding-top: 2px;
      }

      ul {
        padding-left: 0;
        list-style: none;

        li {
          color: setColor('ui', 'text-placeholder');
        }
      }
    }

    &__chips {
      list-style: none;
      padding-left: 0;
      display: inline;

      li {
        display: inline;

        .o-chip {
          margin-bottom: .5rem;
        }
      }
    }

    &__facilities {
      list-style: none;
      padding-left: 0;
      display: inline;
      opacity: .6;

      ul {
        height: 120px;
        overflow: hidden;
        position: relative;
        padding-bottom: 2rem;
        &:before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          height: 50px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%);
        }
        &.show-more {
          height: auto;
        }
      }

      li {
        display: block;
        margin: 0;
        line-height: 1.3;

        span {
          padding-left: .5rem;
          margin-right: 1rem;
        }

        &::before {
          font-family: 'oto-icons-mini' !important;
          content: "\e933";
          font-size: .5rem;
          margin-right: .25rem;
          color: setColor('theme', 't01');
        }
      }
    }

    &__show-more {
      display: block;
      font-size: .75rem;
      color: setColor('theme', 't01');
      text-decoration: underline;
      text-align: right;
      margin-bottom: 1rem;
    }

    &__solution-list {
      list-style: none;
      padding-left: 0;
    }

    &__room {
      font-size: 14px;
      display: flex;
      flex-flow: column wrap;
      gap: .5rem;
      border-bottom: 1px solid setColor('ui', 'border-color');
      user-select: none;

      @media (min-width: 992px) {
        flex-flow: row wrap;
      }

      &-info {
        display: flex;
        flex-flow: column;
        gap: 1rem;
        flex: 2 0;
        padding: 2rem 1rem;

        @media (min-width: 1200px) {
          gap: .5rem;
          padding: 1rem 1.5rem;
        }
      }

      &-title {
        font-weight: 700;
        font-size: 1rem;
        color: setColor('ui', 'text-dark');
        letter-spacing: 0;
        line-height: 23px;
        text-transform: lowercase;

        &:first-letter {
          text-transform: capitalize;
        }
      }

      &-service {
        &-label {
          color: setColor('ui', 'text-dark');
        }
      }

      &-prices {
        margin: .25rem;
        flex: 1 0;
        min-width: 230px;
        background-color: setColor('ui', 'background-ico');
        border-radius: .5rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        gap: .25rem;
        padding: .5rem;
        // @media (min-width: 1200px) {
        //   max-width: 280px;
        // }

        .c-button {
          width: 100%;
          margin-bottom: .75rem;
        }
      }

    }

    &__special-text {
      opacity: 0.6;
      font-size: .875rem;
      line-height: 1.2;
      color: setColor('ui', 'text-placeholder');
      text-align: center;

      &--uppercase {
        text-transform: uppercase;
      }
    }

    &__price {
      font-weight: bold;
      font-size: 28px;
      color: setColor('ui', 'text-dark');
      text-align: center;
    }

    &__show-room {
      flex: 1 0 100%;
      text-align: center;
      border-top: 1px solid setColor('ui', 'border-color');
      padding: .5rem;
      color: $brand-color-01;

      a {
        color: setColor('theme', 't01');
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &--ancillary {
    background: #FFF;
    border: 1px solid setColor('ui', 'border-color');
    box-shadow: map-get($card, box-shadow);
    border-radius: 1rem;

    &__body {
      display: flex;
      flex-flow: column wrap;
      padding: .5rem;
      @media (min-width: 992px) {
        flex-flow: row wrap;
      }
    }

    &__pic {
      height: 100%;
      max-width: 100%;
      aspect-ratio: 4/2;
      @media (min-width: 992px) {
        aspect-ratio: 1/1;
        max-width: 178px;
      }
      picture {
        display: block;
        aspect-ratio: 4/2;
        @media (min-width: 992px) {
          aspect-ratio: 1/1;
        }
      }
      img {
        border-radius: 1rem;
        object-fit: cover;
        max-width: 100%;
        height: 100%;
        aspect-ratio: 4/2;
        @media (min-width: 992px) {
          aspect-ratio: 1/1;
          max-width: 178px;
        }
      }
    }

    &__content {
      flex: 1 0;
      padding: .5rem 1.5rem 0;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      gap: 1rem;
      color: setColor('ui', 'text-placeholder');
      p {
        overflow: hidden;
        position: relative;
        @include multiLineEllipsis($lineHeight: 1.3em, $lineCount: 3, $bgColor: transparent);
      }
      .u-list--inline {
        li:before {
          content: unset;
        }
      }
    }

    &__title {
      font-weight: 700;
      font-size: 1.125rem;
      color: setColor('ui', 'text-dark');
      line-height: 1.2;
    }

    &__description {
      font-size: .875rem;
      line-height: 1.4;
      p {
        font-size: .875rem;
        line-height: 1.4;
        margin-bottom: 0;
      }
    }

    &__bottom {
      flex: 1 0;
      display: flex;
      flex-flow: row;
      align-items: flex-end;
      // width: 100%;
      text-align: right;
      align-self: flex-end;
      &-cta {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        width: 100%;
      }
      &-price {
        font-weight: 700;
        font-size: 1.125rem;
        color: setColor('ui', 'text-dark');
        text-align: right;
        display: inline-block;
        small {
          font-weight: 700;
        }
      }
    }
    &__footer {
      text-align: right;
      padding-bottom: 1rem;
      padding-right: 1rem;
    }
  }
  &--gallery {
    flex-direction: column;
    padding: 0;
    box-shadow: none;
    text-align: center;
    .c-card {
      &__pic {
        flex: 0 0 90%;
        width: 100%;
        border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
        overflow: hidden;

        &:before {
          border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
        }

        img {
          border-radius: map-get($card, border-radius);
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 450px;

          &:before {
            border-radius: map-get($card, border-radius) map-get($card, border-radius) 0 0;
          }
        }
      }
      &__body {
        flex: 0 0 10%;
        width: 100%;
        padding-top: 1rem;
        padding-left: 0;
        h5 {
          width: 100%;
          text-align: center;
          font-weight: 700;
        }
      }
    }
  }
  .c-card-label {
    display: block;
    font-size: .875rem;
    padding: 0.4rem 1.5rem .4rem 0;
    position: relative;
    .icon-date {
      + span {
        font-weight: 600;
      }
    }
    i {
      font-size: 1rem;
      display: inline-block;
      padding-right: .3rem;
      color: $brand-color-02;
      &:before {
        font-weight: 600;
      }
      &.icon-arrow-thick-right, &.icon-arrow-thick-left {
        font-size: .75rem;
        padding-right: .2rem;
      }
      &.icon-check-out {
        border-left: 1px solid #888;
        margin-left: .5rem;
        padding-left: .5rem;
      }
      + span {
        + i {
          border-left: 2px solid #ccc;
          padding-left: .5rem;
          margin-left: .5rem;
        }
      }
    }
    &--inline {
      display: inline-block;
    }
    a {
      color: $brand-color-02;
      text-decoration: underline;
    }
  }
  .c-card-departures {
    display: flex;
    flex-direction: row;
    padding: .8rem 0;
    margin: 1rem 0;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    @media (min-width: 1600px) {
      justify-content: center;
    }
    &--no-border {
      border: 0;
      padding: 0;
    }
    &__label {
      display: block;
      color: $brand-color-04;
      font-weight: 600;
      text-align: center;
    }
    &__item {
      margin: 0 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      i {
        font-size: 1.2rem;
        color: $brand-color-02;
      }
    }
    &__item-packet {
      margin: 0 0rem;
      display: flex;
      align-items: center;
      justify-content: left;
      i {
        font-size: 1.2rem;
        color: $brand-color-02;
      }
    }
    &__detail {
      padding-left: .7rem;
      font-size: .875rem;
      position: relative;
      span, hr {
        display: block;
        padding-right: .5rem;
        @media (min-width: 1600px) {
          display: inline-block;
        }
      }
      hr {
        height: 20px;
        border-left: 2px solid #EF8B19;
        margin: 4px auto;
        border-top: 0;
        width: 2px;
        @media (min-width: 1600px) {
          width: 20px;
          border-top: 2px solid #EF8B19;
          margin: 4px 0;
          border-left: 0;
          height: unset;
        }
      }
    }
  }
  .c-card-tags {
    &__item {
      border-radius: 20px;
      border: 1px solid #000;
      padding: .3rem 1rem .2rem;
      font-size: .765rem;
      margin: .2rem .4rem .2rem 0;
      display: inline-block;
      line-height: 1;

      i {
        display: inline-block;
        margin-right: .3rem;
        line-height: 1.2;
        vertical-align: middle;
      }

      &.active {
        background-color: $brand-color-01;
        color: #fff;
      }

      span {
        display: block;
        text-align: center;
      }
    }
  }
  &.skeleton {
    .c-card {
      &__pic {
        aspect-ratio: 1 / 1;
      }
      &__main-info {
        flex: 1 0 70%;
      }
      &__sub-info {
        margin-top: 1rem;
      }
      &__prices {
        width: 100%;
        span, small {
          display: block;
          width: 100%;
          margin-left: auto;
        }
      }
      &-departures {
        &__item, &__detail {
          width: 100%;
        }
      }
    }
  }
}
