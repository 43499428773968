.c-card-help {
  order: 2;
  @media (min-width: 992px) {   
    order: initial;
  }

  &__container {
    
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
    margin: 0 auto;    
    background: #FFF;
    border: 1px solid setColor('ui', 'border-color');
    box-shadow: map-get($card, box-shadow);
    border-radius: .5rem;

    @media (min-width: 992px) {
      display: block;
      text-align: center;
    }
  }

  &__icon-wrapper {
    text-align: center;
    flex: 0 0 calc(20% - 1rem);
    margin-right: 1rem;
    padding: 1rem;
    @media (min-width: 992px) {
      padding: 0;
    }
  }

  &__icon {
    font-size: 42px;
    color: #19AF39;
    // @media (min-width: 992px) {      
    //   text-align: left;
    // }
  }

  &__texts {
    flex: 0 0 calc(80% - 1rem);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-evenly;
    align-items: center;
    // text-align: left;

    @media (min-width: 992px) {      
      display: contents;
    }
  }
  
  &__title {
    font-weight: bold;
    font-size: 1rem;
    color: setColor('ui', 'text-dark');
    flex: 0 0 100%;
  }
  
  &__message {    
    font-weight: 400;
    flex: 0 0 100%;
    
    @media (min-width: 992px) {      
      font-size: 1rem;
    }

    @media (min-width: 1200px) {      
      font-size: 20px;
    }
  }
}

.detail-solutions {
  flex-flow: column;
  @media (min-width: 992px) {      
    flex-flow: row;
  }
}
