@import "src/global/styles/configurations";

.modal-title {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.modal-footer {
  &.modal-footer {
    &--custom {
      justify-content: flex-start;
      padding: 1rem 1.26rem;
    }
  }
}
.modal-close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: setColor('theme', 't01');
  background: transparent;
  border: 0;
  opacity: 1;
  i {
    display: inline-block;
    margin-left: .5rem;
    &:before {
      font-weight: 700;
    }
  }
}
