/* You can add global styles to this file, and also import other style files */

html, body {
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}

body {
  &.no-scroll {
    overflow-y: hidden;
  }
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/functions";

$success: #01c120;
$info: #0072ca;
$warning: #ffb602;
$danger: #fb0000;

// Breadcrumb
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

// BS Modifier
@import '~bootstrap/scss/bootstrap';
@import "global/styles/configurations";

// Icons
@import "global/styles/icons";
@import "global/styles/icons-tags";
@import "global/styles/icons-generic";
@import "global/styles/oto-icons";

// Base
@import "global/styles/container";
@import "global/styles/colors";
@import "global/styles/typography";
@import "global/styles/mixin/ellipsis";

// Forms
@import "global/styles/forms";

// Modals
@import "global/styles/modal";

// Datepicker
@import "global/styles/datepicker";

// Object
@import "global/styles/objects/o-search";
@import "global/styles/objects/o-page";
@import "global/styles/objects/o-section";
@import "global/styles/objects/o-checkout";
@import "global/styles/objects/o-booking";
@import "global/styles/objects/o-engine";
@import "src/global/styles/objects/o-header";
@import "src/global/styles/objects/o-destinations-thumbnail";
@import "src/global/styles/objects/o-holiday-search";

// Components
@import "global/styles/components/c-button";
@import "global/styles/components/c-modal";
@import "global/styles/components/c-page";
@import "global/styles/components/c-home-slider";
@import "global/styles/components/c-chip";
@import "global/styles/components/c-destinations";
@import "global/styles/components/c-list-column";
@import "global/styles/components/c-passengers-counter";
@import "global/styles/components/c-experience";
@import "global/styles/components/c-card";
@import "global/styles/components/c-mosaik";
@import "global/styles/components/c-solution-nav";
@import "global/styles/components/c-card-tag";
@import "global/styles/components/c-card-help";
@import "global/styles/components/c-card-solution";
@import "global/styles/components/c-section-step";
@import "global/styles/components/c-label-choice";
@import "global/styles/components/c-accordion";
@import "global/styles/components/c-recap";
@import "global/styles/components/c-booking";
@import "global/styles/components/c-row-list";
@import "global/styles/components/c-ancillary-row";
@import "global/styles/components/c-card-registry";
@import "global/styles/components/c-card-insurance";
@import "global/styles/components/c-date-pagination";
@import "global/styles/components/c-transports";
@import "global/styles/components/c-filters";
@import "global/styles/components/c-search-edit";
@import "global/styles/components/c-search-cta";

// Utilities
@import "global/styles/utilities/u-scrollbar";
@import "global/styles/utilities/u-scrollsnap";
@import "global/styles/utilities/u-list";
@import "global/styles/utilities/u-background";
@import "global/styles/utilities/u-text";
@import "global/styles/utilities/u-dashed-border";
@import "global/styles/utilities/u-cursor";

@import "~@ng-select/ng-select/themes/default.theme.css";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Custom pages - TEMP */
@import 'global/styles/custom-pages/gazzetta.scss';



:root {
  --gutter: .5rem;
  --margin: -.5rem;
}
.container-fluid {
  width: 100%;
  max-width: 100vw;
}

.container-xl {
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 960px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 1180px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 1320px;
  }
  @media (min-width: 1600px) {
    max-width: 1440px;
  }
}
.container-lg {
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 820px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 1080px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 1180px;
  }
  @media (min-width: 1600px) {
    max-width: 1280px;
  }
}
.container-md {
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 820px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 1080px;
  }
  @media (min-width: 1600px) {
    max-width: 1180px;
  }
}
.container-sm {
  @media (min-width: 1600px) {
    max-width: 1080px;
  }
}

// Questo sistema il padding e i margini di bootstrap secondo il modello Conad
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {

  // overflow-x: hidden;
  // max-width: 375px;
  padding-right: calc(var(--gutter) * 2);
  padding-left: calc(var(--gutter) * 2);
  .row {
    max-width: 100vw;
    margin-left: var(--margin);
    margin-right: var(--margin);

    >.col,
    >[class*="col-"] {
      padding-right: var(--gutter);
      padding-left: var(--gutter);
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    // max-width: 752px;
    padding-right: var(--gutter);
    padding-left: var(--gutter);
    // margin: 0 48px;

    .row {
      margin-left: var(--margin);
      margin-right: var(--margin);

      >.col,
      >[class*="col-"] {
        padding-right: var(--gutter);
        padding-left: var(--gutter);
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    // max-width: 1344px;
    padding-right: calc(var(--gutter) + calc(var(--gutter) /2 ));
    padding-left: calc(var(--gutter) + calc(var(--gutter) /2 ));
    // margin: 0 48px;

    .row {
      margin-left: calc(var(--margin) + calc(var(--margin) /2 ));
      margin-right: calc(var(--margin) + calc(var(--margin) /2 ));

      >.col,
      >[class*="col-"] {
        padding-right: calc(var(--gutter) + calc(var(--gutter) /2 ));
        padding-left: calc(var(--gutter) + calc(var(--gutter) /2 ));
      }
    }
  }
}

//  Widget liveChat, per evitare che si sovrapponga alle cta
@media screen and (max-width: 992px) {
  .umm-1ikmx2u {
    bottom: 100px !important;
  }

}

#linkutili {
  a {
    text-decoration: underline !important;
    color: #0061a0 !important;
  }
}

.swiper-wrapper {
  @media (max-width: 768px) {
    padding-top: 3rem;
  }
}
