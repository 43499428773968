@import "colors.scss";

$assets-path: './assets';

// Spacing and Margin
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .5),    //8px
  2: $spacer,           //16px
  3: ($spacer * 1.5),   //24px
  4: ($spacer * 2),     //32px
  5: ($spacer * 2.5),   //40px
  6: ($spacer * 3.75),  //60px
  7: ($spacer * 7.5)    //120px
);
// Usage: map-get($spacers, 2);

// Font
$body-font-primary: 'Poppins', sans-serif;
$font-weights: (
  light:    300,
  regular:  400,
  medium:   500,
  bold:     700
);
// Usage: map-get($font-weights, bold);

// Buttons configurations
$btn: (
  font-size:      .875rem,
  font-weight:    600,
  border-width:   .125rem,
  border-radius:  8px,
  hover-ring:     0 0 0 0.125rem,
  focus-ring:     0 0 0 .0625rem
);
// Usage: map-get($btn, font-size);

// Cards configurations
$card: (
  border-radius:  8px,
  body-padding:   1.5rem,
  pic-shadow:     linear-gradient(to bottom, setColor('dark', 'darken-100'), setColor('dark', 'darken-0')),
  box-shadow:     (0 8px 16px -4px rgba(51, 51, 51, 0.15), 0 0 1px 0 rgba(51, 51, 51, 0.2)),
);
// Usage: map-get($card, border-radius);

// Buttons configurations
$input: (
  border-radius:  8px,
);
// Usage: map-get($input, border-radius);

// Buttons configurations
$checkbox: (
  size:    20px,
  border-radius:   4px,
);
// Usage: map-get($checkbox, size);

// Shadows and Elevations
$shadow: (
  1: (0 1px 1px 0 rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2)),
  2: (0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2)),
  3: (0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2)),
  4: (0 12px 24px -6px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2)),
  5: (0 20px 32px -8px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2))
);
// Usage: map-get($shadow, 3);

$page: (
  header-height: 4rem,
);


// Base Tune
$font-size-base:    1rem;
$line-height-base:  1.2;

// Colors

$primary: setColor('theme', 't03');
$success: setColor('semantic', 'success');
$info:    setColor('semantic', 'info');
$warning: setColor('semantic', 'warning');
$danger:  setColor('semantic', 'danger');


// Input
$input-border-color:                      setColor('dark', 'darken-30');
$input-focus-border-color:                setColor('dark', 'darken-80');

// Forms
$form-check-input-width:                  1.25em;
$form-check-min-height:                   $font-size-base * $line-height-base;
$form-check-padding-start:                $form-check-input-width + .5em;
$form-check-margin-bottom:                map-get($spacers, 2);
$form-check-transition:                   box-shadow .15s ease-in-out, border-color .15s ease-in-out, background-image .15s ease-in-out, color .15s ease-in-out;
$form-check-input-border:                 2px solid #b3b3b3;
$form-check-input-checked-bg-color:       setColor('theme', 't03');
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color;
$form-check-input-focus-border:           transparentize(setColor('theme', 't03'), 0.9);
$form-check-input-focus-box-shadow:       0 0 10px -2px #f38933;

$form-check-inline-margin-end:            map-get($spacers, 2);

// Select
$form-select-bg-size:                     16px 16px;
$form-select-indicator-color:             setColor('dark', 'darken-80');
$form-select-indicator:                   url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik0wIDBoMTZ2MTZIMHoiLz4KICAgICAgICA8cGF0aCBkPSJtNy40NjggMTAuNzY0LTQuMjYtNC40ODhhLjc1Ni43NTYgMCAxIDEgMS4wOTgtMS4wNGwzLjcxIDMuOTEgMy42NzktMy44NzVhLjc1Ni43NTYgMCAxIDEgMS4wOTcgMS4wNDJsLTQuMjI2IDQuNDUxYS43NTUuNzU1IDAgMCAxLTEuMDk4IDB6IiBmaWxsPSIjMzMzIi8+CiAgICA8L2c+Cjwvc3ZnPgo=");

$form-select-border-color:                $input-border-color;
$form-select-focus-width:                 2px;
$form-select-focus-box-shadow:            0 0 0 $form-select-focus-width setColor('dark', 'darken-80');

$form-select-focus-border-color:          $input-focus-border-color;
