$icomoon-font-family: "icoPremium" !default;
$icomoon-font-path: "../../assets/fonts" !default;

$icon-pdf-download: "\e942";
$icon-home-heart: "\e983";
$icon-shield-check: "\e982";
$icon-24-hours: "\e97e";
$icon-creativity: "\e97f";
$icon-pencil-ruler: "\e980";
$icon-shield-empty: "\e981";
$icon-pencil: "\e97d";
$icon-park: "\e97b";
$icon-rental: "\e97c";
$icon-arrow: "\e97a";
$icon-group-people-sm: "\e978";
$icon-age: "\e979";
$icon-target: "\e977";
$icon-arrows: "\e976";
$icon-sunflower: "\e975";
$icon-options: "\e973";
$icon-order-list: "\e974";
$icon-pin-map: "\e972";
$icon-appointment: "\e96a";
$icon-call-assistance: "\e96c";
$icon-group-people: "\e96d";
$icon-hotel-transfer: "\e96e";
$icon-med-assurance: "\e96f";
$icon-shield-thin: "\e970";
$icon-user-info: "\e971";
$icon-chat: "\e968";
$icon-facebook-square: "\e969";
$icon-instagram: "\e96b";
$icon-airplane: "\e962";
$icon-no-plane: "\e966";
$icon-pin-shape-thin: "\e967";
$icon-pins-shape-thin: "\e959";
$icon-arrow-left: "\e93b";
$icon-arrow-right: "\e93c";
$icon-arrow-thick-left: "\e93d";
$icon-arrow-thick-right: "\e93e";
$icon-calendar: "\e93f";
$icon-champagne: "\e940";
$icon-check: "\e941";
$icon-clock: "\e943";
$icon-event: "\e944";
$icon-eye: "\e945";
$icon-fire: "\e946";
$icon-gallery: "\e947";
$icon-gastronomia: "\e948";
$icon-golf: "\e949";
$icon-group-lg: "\e94a";
$icon-help: "\e94b";
$icon-key: "\e94c";
$icon-landing: "\e94d";
$icon-list: "\e94e";
$icon-love: "\e94f";
$icon-minus-rounded: "\e950";
$icon-palm-tree: "\e951";
$icon-phone: "\e952";
$icon-pin-full: "\e953";
$icon-pin: "\e954";
$icon-pins: "\e955";
$icon-plus-rounded: "\e956";
$icon-sagre: "\e957";
$icon-sea: "\e958";
$icon-share: "\e95a";
$icon-shield-pro: "\e95b";
$icon-shield: "\e95c";
$icon-sport-adventure: "\e95d";
$icon-star: "\e95e";
$icon-take-off: "\e95f";
$icon-temptation: "\e960";
$icon-treatment: "\e961";
$icon-value: "\e963";
$icon-wellness: "\e964";
$icon-wheat: "\e965";
$icon-hours: "\e934";
$icon-free-canc: "\e935";
$icon-guide: "\e936";
$icon-insurance: "\e937";
$icon-luggage: "\e938";
$icon-transfer: "\e939";
$icon-virus: "\e93a";
$icon-whatsapp: "\e933";
$icon-alert: "\e900";
$icon-catalogue: "\e901";
$icon-chevron-down: "\e902";
$icon-chevron-left: "\e903";
$icon-chevron-right: "\e904";
$icon-chevron-up: "\e905";
$icon-close: "\e906";
$icon-contract: "\e907";
$icon-cruise: "\e908";
$icon-date: "\e909";
$icon-directions: "\e90a";
$icon-double-bed: "\e90b";
$icon-email: "\e90c";
$icon-facebook: "\e90d";
$icon-fax: "\e90e";
$icon-file: "\e90f";
$icon-group: "\e910";
$icon-heart: "\e911";
$icon-id-card: "\e912";
$icon-info: "\e913";
$icon-instagram-shape: "\e914";
$icon-location: "\e915";
$icon-lock: "\e916";
$icon-logout: "\e917";
$icon-menu: "\e918";
$icon-minus: "\e919";
$icon-phone-shape: "\e91a";
$icon-pin-shape: "\e91b";
$icon-pinterest: "\e91c";
$icon-plane: "\e91d";
$icon-plus: "\e91e";
$icon-poster: "\e91f";
$icon-print: "\e920";
$icon-question: "\e921";
$icon-quote: "\e922";
$icon-report: "\e923";
$icon-reset: "\e924";
$icon-search: "\e925";
$icon-service: "\e926";
$icon-settings: "\e927";
$icon-single-bed: "\e928";
$icon-tick: "\e929";
$icon-ticket: "\e92a";
$icon-time: "\e92b";
$icon-tour: "\e92c";
$icon-trash: "\e92d";
$icon-twitter: "\e92e";
$icon-url: "\e92f";
$icon-user: "\e930";
$icon-view: "\e931";
$icon-youtube: "\e932";
