.c-modal {
    &--full-screen {
        position: fixed;
        z-index: 1050;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw !important;
        height: 100vh !important;
        overflow: scroll;
        background-color: white;
    }
}

.modal-close:focus-visible{
    outline: none;
}