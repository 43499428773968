@import "src/global/styles/configurations";

$brand-color-01: #005B9A;
$brand-color-03: #FBA610;

.btn-brand-color{
  background-color: $brand-color-01;
  border: 0;
  border-radius: 5px;
  padding: 10px 10px;
}

.back-button{
  width: 100%;
}

@media (min-width: 760px) {
  .back-button{
    width: 85%;
  }
}

.search-edit-text{
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .search-edit-text{
    margin-bottom: 0;
  }
}

.c-search-edit {
  background-color: setColor('theme', 't01');
  padding: 1rem;
  color: #fff;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  cursor: pointer;
  &__destination {
    color: setColor('neutral', 'n01');
    font-size: 1.125rem;
  }
  &__cta {
    background-color: setColor('theme', 't04');
    border-radius: 100%;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 1rem;
    top: 30%;
    text-align: center;
    line-height: 3.6;
    @media (min-width: map-get($grid-breakpoints, md)) {
      display: none;
    }
    i {
      color: setColor('neutral', 'n01');
      font-size: 1.3rem;
    }
  }
  span {
    display: block;
    line-height: 2;
    font-size: 0.875rem;
    i {
      display: inline-block;
      margin-right: 0.5rem;
      font-size: 1rem;
      width: 20px;
    }
  }
  &__link {
    text-decoration: underline;
    display: none;
    font-weight: 500;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      display: block;
    }
  }
}
