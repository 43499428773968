@import "src/global/styles/_configurations";

ngb-datepicker {

  &.datepicker {
    width: 100%;
    border: 0;
    position: relative;
    [ngbDatepickerDayView] {
      font-weight: 700;
      color: setColor('theme', 't01');
      &.outside {
        font-weight: 400;
        opacity: .35;
      }
    }

    .ngb-dp-arrow {
      //display: none;
      .ngb-dp-arrow-btn {
        color: #888;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }

      @media (min-width: 992px) {
        .ngb-dp-arrow-btn{
          margin: 0 0.1rem;
        }
      }
    }
    .ngb-dp-header {
      padding: .5rem;
      background-color: #fff;
    }
    
    @media (min-width: 992px) {
      .ngb-dp-header {
        font-size: 12px;
        height: 75px;
      }
    }

    .ngb-dp-weekdays {
      margin-bottom: .5rem;
    }
    .ngb-dp-navigation-select {
      flex: 1 1 2rem;
      // @media (min-width: 992px) {
        position: absolute;        
        left: 50%;
        transform: translateX(-50%);
      // }

      select {
        border-radius: 0;
        border: 0;
        // width: 90px;
        margin-right: 0 !important;
        padding: 0 0px;
        color: setColor('theme', 't01');
        font-weight: 700;
        font-family: $body-font-primary;
        text-transform: uppercase;
        font-size: 1rem;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }

      @media (min-width: 992px){
        select {
          font-size: 12px;
        }
      }

      @media (min-width: 1400px){
        select {
          font-size: 14px;
        }
      }

    }
    ngb-datepicker-month {
      width: 100%;
    }
    .ngb-dp-month {
      width: 100%;
      padding: 0 .5rem;
      .ngb-dp-month-name {
        font-size: 1.1rem;
        height: auto;
        background-color: transparent;
        line-height: 1.4;
      }
    }
    .ngb-dp-week {
      background-color: #fff;
    }
    .ngb-dp-weekday {
      font-style: normal;
      color: #434343;
      font-family: $body-font-primary;
      font-size: .600rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    .ngb-dp-day {
      color: #343434;
      font-family: $body-font-primary;
      font-size: .775rem;
      font-weight: 500;
      height: 35px;
      text-align: center;
      &:focus {
        border-color: transparent;
        box-shadow: none;
        outline: 0;
      }
    }
    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
      width: calc(100% / 7);
    }
    [ngbDatepickerDayView] {
      margin: 0;
      text-align: center;
      height: 100%;
      line-height: 2.7;
      width: 35px;
      border-radius: 100%;
      &:focus {
        border-color: transparent;
        box-shadow: none;
        outline: 0;
      }
      &.bg-primary {
        background-color: transparentize(setColor('theme', 't02'), .4) !important;
        color: setColor('theme', 't01') !important;
        &:focus {
          border-color: transparent;
          box-shadow: none;
          outline: 0;
        }
      }
    }
    .ngb-dp-months {
      position: relative;
      @media (min-width: 992px) {
        top: -2.3rem;
      }
      .ngb-dp-month-name {
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 600;
      }
      .ngb-dp-day {
        [ngbdatepickerdayview] {
          margin: .5rem auto;
          border-radius: 0;
        }
      }
    }

    // &--filters {
    //   .ngb-dp-navigation-select {
    //     flex: 1 1 6rem;
    //     position: relative;
    //     top: 0;
    //     left: unset;
    //   }
    //   .ngb-dp-months {
    //     top: 0 !important;
    //   }
    //   .ngb-dp-navigation-select select {
    //     width: 55px;
    //   }
    // }
    &--hotel {
      .ngb-dp-months {
        flex-direction: column;
        @media (min-width: 992px) {
          flex-direction: row;
        }
      }
    }
    &--holiday {
      .ngb-dp-header {
        // padding-top: 1.5rem;
        .ngb-dp-arrow {
          // @media (min-width: 992px) {
          //   top: -1.5rem;           
          // }
          position: relative;
        }
      }
      // .ngb-dp-navigation-select {
      //   top: -5px;
      //   left: 39%;
      // }
    }
  }

  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 100%;
    line-height: 2;
  }

  .custom-day.focused {
    background-color: #e6e6e6;
  }

  .custom-day.disabled {
    // background-color: #e6e6e6;
    opacity: .25;
  }

  .custom-day.range, .custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
  }

  .custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }


  // ------ (Dropdown HomePage) ------

  .btn-light:hover, .btn-light:active {
    background-color: #0275d8;
    border-color: #0275d8;
    color: #fff;
  }

  ngb-datepicker-navigation-select>.custom-select {
    border: 0;  
    &:focus-visible{
      outline: none;
    }
  }

  .btn {
    font-size: 10px;
    &:focus{
      box-shadow: none;
    }
  }

  @media (min-width: 768px) {
    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
      width: 2.75rem;
    }
  }
}
