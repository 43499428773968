@import "src/global/styles/configurations";

.c-mosaik {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    flex-flow: column wrap;
    height: 400px;
  }

  &__big {
    flex: 1 0 100%;
    height: 300px;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 1 0;
      width: 50%;
      height: 100%;
    }

    &.is-1 {
      width: 100%;
    }

    .c-mosaik__item {
      flex: 1;
    }
  }

  &__small {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    gap: 8px;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 1 0 calc(50% - #{map-get($spacers, 2)});
      gap: 8px;
      max-height: 400px;
      width: calc(50% - #{map-get($spacers, 1)});
    }

    &.is-2 {
      .c-mosaik__item {
        height: 100%;
      }
    }

    .c-mosaik__item {
      flex: 1 0 calc(25% - #{map-get($spacers, 1)});
      max-width: calc(25% - #{map-get($spacers, 1)});
      height: 110px;
      @media (min-width: 992px) {
        flex: 1 0 calc(50% - #{map-get($spacers, 2)});
        max-width: unset;
        width: 50%;
        height: calc(50% - 0.25rem);
      }
    }
  }

  &__item {
    display: flex;
    flex: 1 0;
    height: 100%;
    border-radius: 16px;

    position: relative;
    overflow: hidden;

    figure {
      width: 100%;
      margin: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  &__cta {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
    color: #fff;
    border: 1px solid #fff;
    border-radius: .5rem;
    padding: 0.532rem 1rem;
    a {
      display: flex;
      align-items: center;
      font-weight: 800;
      font-size: 13px;
      text-transform: uppercase;
      color: #FFF;
      text-align: center;
      .icon-gallery {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
  figcaption {
    position: absolute;
    bottom: 0;
    left: 3px;
    z-index: 2;
    background-color: transparentize(setColor('neutral', 'n02'), .7);
    right: 3px;
    padding: .75rem 1rem;
    color: #fff;
    font-size: .75rem;
  }
}

.c-single-image {
  position: relative;
  img {
    width: 100%;
    aspect-ratio: 3/1;
    object-fit: cover;
    object-position: 50% 65%;
    border-radius: map-get($card, border-radius)
  }
  figcaption {
    position: absolute;
    bottom: 0;
    left: 3px;
    z-index: 2;
    background-color: transparentize(setColor('neutral', 'n02'), .7);
    right: 3px;
    padding: .75rem 1rem;
    color: #fff;
    font-size: .75rem;
  }
}
