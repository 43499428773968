$engine-cta-width: 180px;

.o-search {
  &__engine {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    .nav.nav-tabs {
      display: none;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      flex-flow: column wrap;
      .tab-pane.active {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1050;
        background: #fff;
      }
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
      .o-search__engine {
        .tab-pane {
          position: relative;
        }
      }
    }
  }

  &__input {
    border: none;
    background: transparent;
    flex: 1;
    flex-flow: row nowrap;
    gap: 1rem;

    @media (min-width: map-get($grid-breakpoints, md)) {
      flex: 1 0 48%;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-flow: row nowrap;
      flex: 1;
      align-items: flex-end;
    }
    .form-group-custom {
      margin-bottom: 0;
      width: 100%;
    }
  }


  .o-search-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: map-get($input, border-radius);
    cursor: pointer;
    user-select: none;
    flex: 1 0;
    max-width: 100%;
    //margin: 0 map-get($spacers, 2) map-get($spacers, 2);
    position: relative;
    height: 3rem;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin: 0;
      flex: 1 0 48%;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin: 0;
      flex: 1 0;
    }

    &.is-disabled, &[disabled] {
      opacity: .50;
      pointer-events: none;
      background-color: #dbdbdb !important;
      + .input-group-custom__append, .input-group-custom__prepend {
        .input-group-custom__icon {
          color: #444;
          opacity: .5;
        }
      }
    }
  }

  .o-search-cta {
    //margin: 0 map-get($spacers, 2) map-get($spacers, 1);
    align-self: flex-end;
    height: 50px;
    border-radius: 8px;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, md)) {
      flex: 1 0 100%;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      margin: 0;
      flex: unset;
      border-radius: 8px;
      width: auto;
    }

    a {
      padding: .5rem 1.5vw;
      height: calc(1.5rem + 0.938rem + 2px);
      display: flex;
      align-items: center;
    }
  }

}
