@import "src/global/styles/configurations";

.u-list {

  li {
    margin-bottom: .675rem;
  }

  &--o-hidden {
    overflow: hidden;
  }

  &--unstyled {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: .5rem;
    }
  }

  &--facilities {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: unset;
    margin-bottom: 0;

    @media (min-width: 992px) {
      flex-flow: row wrap;
    }

    li {
      padding-left: 0.5rem;
      border-right: 1px solid #ddd;
      margin-bottom: 0;
      line-height: 2;
      margin-right: 1.5rem;
      padding-right: 1.5rem;
      max-width: 100%;
      @media (min-width: 768px) {
        max-width: 47%;
      }
      @media (min-width: 992px) {
        max-width: 30%;
      }
      span {
        padding-left: .5rem;
      }

      &::marker {
        font-family: 'oto-icons-mini' !important;
        content: "\e933";
        font-size: 14px;
        margin-right: .5rem;
        color: setColor('theme', 't01');
      }
    }
  }

  &--listed {
    padding: 0;

    li {
      padding-left: 0.5rem;
      margin-bottom: 0;
      line-height: 2;
      margin-right: 1.5rem;
      padding-right: 1.5rem;
      span {
        padding-left: .5rem;
      }
    }
    &.included {
      li {
        &::marker {
          font-family: 'icoPremium' !important;
          content: "\e941";
          font-size: 14px;
          margin-right: .5rem;
          color: setColor('semantic', 'success');
        }
      }
    }
    &.not-included {
      li {
        &::marker {
          font-family: 'icoPremium' !important;
          content: "\e906";
          font-size: 14px;
          margin-right: .5rem;
          color: setColor('semantic', 'error');
        }
      }
    }
  }

  &--inline {
    display: inline;

    li {
      display: inline;

      span {
        padding-left: .5rem;
        margin-right: 1rem;
      }

      &::before {
        font-family: 'oto-icons-mini' !important;
        content: "\e933";
        font-size: .5rem;
        margin-right: .25rem;
        color: setColor('theme', 't01');
      }
    }
  }

  &--dots {
    display: flex;
    flex-flow: column wrap;

    li {
      line-height: 1.2;

      &::marker {
        font-size: 23px;
        margin-right: .5rem;
        line-height: 1.2rem;
        color: setColor('theme', 't01');
      }
    }
  }
}
