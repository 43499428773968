.c-button {
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-size: map-get($btn, font-size);
  font-weight: map-get($btn, font-weight);
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  text-shadow: none;
  position: relative;
  float: none;
  padding: map-get($spacers, 2) map-get($spacers, 4);
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow .15s ease-in-out, border-color .15s ease-in-out, background-color .15s ease-in-out, color .15s ease-in-out;
  user-select: none;
  border-radius: map-get($btn, border-radius);

  &:before {
    content: "";
    display: inline-block;
    height: map-get($btn, font-size);
    vertical-align: middle;
  }

  &:focus,
  &:focus-visible {
    outline-color: inherit;
    transition: box-shadow .15s ease-in-out, border-color .15s ease-in-out, background-color .15s ease-in-out, color .15s ease-in-out;
  }

  &:hover {
    transition: box-shadow .15s ease-in-out, #ff8c00 .15s ease-in-out, #ff8c00 .15s ease-in-out, color .15s ease-in-out;
    text-decoration: none;
  }

  &.disabled,
  &.is-disabled,
  &[disabled] {
    background-color: setColor("btnDirect", "disable");
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

  &.t-primary {
    color: #FFF !important;
    background-color: setColor("btnDirect", "normal");
    border: map-get($btn, border-width) solid setColor("btnDirect", "normal");

    &:hover {
      color: #FFF !important;
      background-color: #ff8c00;
      border-color: #ff8c00;
    }

    &:active {
      text-decoration: none;
    }

    &:focus {
      color: #FFF;
      box-shadow: map-get($btn, focus-ring) inherit;
    }
  }

  &.t-secondary {
    color: #FFF !important;
    background-color: setColor("btnSecondary", "normal");
    border: map-get($btn, border-width) solid setColor("btnSecondary", "normal");

    &:hover {
      color: #FFF !important;
      background-color: #006bb8;
      border-color: #006bb8;
    }

    &:active {
      text-decoration: none;
    }

    &:focus {
      color: #FFF;
      box-shadow: map-get($btn, focus-ring) inherit;
    }
  }

  &.t-accent {
    color: #FFF !important;
    background-color: setColor("btnAccent", "normal");
    border: map-get($btn, border-width) solid setColor("btnAccent", "normal");

    &:hover {
      color: #FFF !important;
      background-color: #00a7a3;
      border-color: #00a7a3;
    }

    &:active {
      text-decoration: none;
    }

    &:focus {
      color: #FFF;
      box-shadow: map-get($btn, focus-ring) inherit;
    }
  }

  &.t-white {
    color: setColor('dark', 'darken-80');
    background-color: setColor('light', 'lighten-100');
    border: map-get($btn, border-width) solid setColor('light', 'lighten-100');

    // &:hover {
    //   background-color: setColor("btnDirect", "hover");
    //   border-color: setColor("btnDirect", "hover");
    //   transition: background-color .33s ease-in, color .22s ease-in;
    // }

    &:active {
      text-decoration: none;
    }

    &:focus {
      box-shadow: map-get($btn, focus-ring) setColor("btnInverse", "hover");
    }
  }

  &.t-outline {
    color: setColor('dark', 'darken-80');
    background-color: transparent;
    border: map-get($btn, border-width) solid setColor("btnInverse", "normal");

    &:hover {
      border-color: setColor("btnInverse", "hover");
    }

    &:active {
      text-decoration: none;
    }

    &:focus {
      box-shadow: map-get($btn, focus-ring) setColor("btnInverse", "normal");
    }

    &--white {
      color: setColor('light', 'lighten-100');
      border: map-get($btn, border-width) solid setColor('light', 'lighten-100');
    }


    &--primary {
      color: setColor('theme', 't01');
      border: map-get($btn, border-width) solid setColor('theme', 't01');
    }

  }

  &--xs {
    font-size: .685rem;
    padding: .625rem 1.5rem;
    &:before {
      content: "";
      display: inline-block;
      height: .685rem;
      vertical-align: middle;
    }
  }

  &--sm {
    font-size: .8125rem;
    padding: .625rem 1.25rem;
    &:before {
      content: "";
      display: inline-block;
      height: .8125rem;
      vertical-align: middle;
    }
  }

  &--md {
    font-size: .75rem;
    padding: .9125rem 2rem;
    letter-spacing: 0.7px;
    &:before {
      content: "";
      display: inline-block;
      height: .75rem;
      vertical-align: middle;
    }
  }

  &--lg {
    font-size: 1rem;
    padding: 1.25rem 2rem;
    &:before {
      content: "";
      display: inline-block;
      height: 1rem;
      vertical-align: middle;
    }
  }

  &--wider {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  &--block {
    width: 100%;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--icon {
    [class*="icon-"],
    .o-icon {
      @media (min-width: map-get($grid-breakpoints, md)) {
        margin-right: map-get($spacers, 1);
      }
    }
  }
}
