@import "src/global/styles/configurations";

.c-solution-nav {
  border-bottom: 3px solid #ddd;
  margin: map-get($spacers, 2) 0;

  &__list {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    font-size: 16px;
    width: 100%;
    margin: 0 auto;
    padding: .75rem 0;
    // overflow-x: auto;
    scroll-padding: 0 50%;
    scroll-snap-type: x mandatory;

    @media (min-width: 992px) {
      scroll-padding: 0;
    }

    li {
      scroll-snap-align: start;
      font-weight: 700;
      font-size: 1rem;
      color: setColor('ui', 'text-dark');
      margin: 0 1rem;
      white-space: nowrap;

      @media (min-width: 992px) {
        margin: 0 1rem 0 0;
        flex: unset;
      }

      a {
        cursor: pointer;
        text-decoration: none;
        color: setColor('ui', 'text-dark');
        font-family: "Poppins";

        &:hover {
          text-decoration: underline;
        }
      }
    }

  }
}
