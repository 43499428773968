@import "src/global/styles/configurations";

.c-card-tag {
  display: flex;
  margin: 0 1rem 0 0;
  flex: 0 0 calc(50% - 1.25rem);
  max-width: 160px;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 1.25rem;
  transition: all .33s ease;
  box-shadow: none;
  position: relative;
  cursor: pointer;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .33s cubic-bezier(.165, .84, .44, 1);
    box-shadow: map-get($card, box-shadow);
    content: '';
    opacity: 0;
    pointer-events: none;
    border-radius: 0.5rem;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .33s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: map-get($card, box-shadow);
    content: '';
    opacity: 1;
    pointer-events: none;
    border-radius: 0.5rem;
  }

  &:hover,
  &:focus {
    //transform: scale3d(1.006, 1.006, 1);
    -webkit-backface-visibility: hidden;

    &::after {
      opacity: 1;
    }
  }

  &__pic {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 2.125rem;
    width: 2.125rem;
    height: 2.125rem;
    background: setColor('ui', 'background-ico') center/cover;
    margin-bottom: 0.625rem;

    &--icon {
      height: 2.625rem;
      width: 2.625rem;
      font-size: 1.5rem;
      color: $brand-color-01;
    }
  }

  &__title {
    user-select: none;
    text-align: center;
    color: setColor('ui', 'text-dark');
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14px;
  }

  &__distance {
    user-select: none;
    text-align: center;
    color: setColor('ui', 'text-placeholder');
    margin-top: .5rem;
  }

  &.active,
  &.is-active {
    background: #FFFFFF;
    border: 1px solid setColor('theme', 't01');
    box-shadow: 0 5px 20px 0 rgba(97, 145, 226, 0.10), 0 0 5px 0 setColor('theme', 't01');
    border-radius: 0.5rem;
    color: setColor('theme', 't01');
    transition: all .33s ease;

    .u-tags__pic--icon {
      color: setColor('theme', 't01');
    }
  }

  &.disabled,
  &.is-disabled {
    opacity: .6;
    pointer-events: none;
  }
}
