.select-destination {
  width: 100%;
  padding: 1rem;
  &__item {
    width: 100%;
    cursor: pointer;
    margin-bottom: .5rem;
    padding: .5rem;
    border-radius: .5rem;
    line-height: 1.2;
    &--airport {
      margin-bottom: 1rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
    }
    &--no-flight {
      // padding-bottom: 1rem;
      border-bottom: 1px solid setColor('ui', 'border-color');
    }
    &:hover {
      background-color: transparentize(setColor('theme', 't01'), .95);

    }
    &:active {
      background: #fff;
      color: inherit;
    }
    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
}

.input-group-custom {
  &.dropdown-toggle {
    &:after {
      display: none;
    }
  }
}

.dropdown-menu {

  &__back {
    display: flex;
    color: setColor('theme', 't01') !important;
    align-items: center;
    gap: 0 0.5rem;
    &-label {
      text-decoration: underline;
    }
    .icon-chevron-left {
      text-decoration: none;
      font-size: 13px;

    }
    &:active {
      background: #fff;
    }
    &:hover {
      background: #fff;
      .icon-chevron-left {
        text-decoration: none;
      }
    }
  }
}

.destination-item {
  background: #FFF;
  border: 1px solid setColor('ui', 'border-color');
  box-shadow: map-get($card, box-shadow);
  border-radius: 8px;
  padding: 4px;
  display: flex;
  align-items: center;
  height: 4rem;
  gap: .5rem;

  &__title {
    display: flex;
    .icon-chevron-left {
      margin-right: .5rem;
    }
  }

  &__pic {
    max-width: 54px;
    flex-basis: 54px;
    aspect-ratio: 1 / 1;
    position: relative;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  &__label {
    font-weight: 500;

  }
}
