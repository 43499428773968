.c-row-list {
  display: flex;
  flex-flow: column;
  background-color: #fff;
  border: 1px solid setColor('ui', 'border-color');
  border-radius: 1rem;
  list-style: none;
  padding-left: 0;

  li {
    &:not(:last-of-type) {
      border-bottom: 1px solid setColor('ui', 'border-color');
    }
  }
}
