/* _form-group-custom.scss */
.form-group-custom {
  position: relative;
  margin-bottom: 1rem; /* 16px */
  &__label {
    display: block;
    font-size: 1rem; /* 16px */
    color: setColor('ui', 'text-dark');
    letter-spacing: .03rem;
    margin-bottom: 0.5rem; /* 8px */
    @media (min-width: 997px) {
      font-size: 0.875rem; /* 14px */
    }
    &.color-light {
      color: setColor('uiColors', 'u03');
    }
    &.color-white {
      color: #fff;
    }
    &.color-dark {
      color: #000;
    }
  }
  &__caption {
    display: block;
    margin-top: 0.25rem;
    width: 85%;
    color: setColor('uiColors', 'u01');
    font-style: italic;
    font-size: 0.75rem; /* 12px */
    &.invalid,
    &.is-invalid {
      color: setColor('semantic', 'error');
    }
    &.valid,
    &.is-valid {
      color: setColor('semantic', 'success');
    }
    &.warning,
    &.is-warning {
      color: setColor('semantic', 'warning');
    }
  }
}
/* _form-group-custom.scss END */
