.c-destinations {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /*padding: 0 1rem;*/
  flex-wrap: wrap;
  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 992px) {
      display: block;
    }
  }
  &__item {
    border-radius: 8px;
    border: 1px solid #888;
    flex: 0 0 45%;
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: #fff;
    margin: .5rem;
    position: relative;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    @media (min-width: 992px) {
      flex-flow: row;
      flex: 0 0 100%;
    }
    /*@media(min-width: 992px) {
      flex: 0 0 47.445%;
      max-width: 47.445%;
    }
    @media(min-width: 1199px) {
      flex: 0 0 29.445%;
      max-width: 29.445%;
    }
    @media(min-width: 1440px) {
      flex: 0 0 23.445%;
      max-width: 23.445%;
    }
    @media(min-width: 1599px) {
      flex: 0 0 18.445%;
      max-width: 18.445%;
    }*/
    &:hover {
      color: initial;
      text-decoration: none;
    }
    &.active {
      border-color: $brand-color-03;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background-color: transparentize($brand-color-03, .3);
        @media (min-width: 992px) {
          width: 85px;
        }
      }
      &:after {
        content: '\2713';
        position: absolute;
        left: 35%;
        top: 5%;
        font-size: 5rem;
        color: #fff;
        @media (min-width: 992px) {
          left: 32px;
          top: 22px;
          font-size: 2rem;
        }
      }
    }
  }
  &__img {
    height: 85px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-size: cover;
    background-position: center;
    @media (min-width: 992px) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0;
      width: 85px;
    }
  }
  &__title {
    padding: 1rem;
    background-color: #fff;
    font-weight: 500;
    color: $brand-color-01;
    font-family: "Roboto Slab", sans-serif;
    text-align: center;
    @media (min-width: 992px) {
      text-align: left;
    }
  }
}
