.c-card-solution {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 0;
  &__item {
    margin-bottom: .5rem;

    &--cta {
      font-size: .75rem;
      color: setColor('theme', 't01');
      text-decoration: underline;
      text-align: right;
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  &-packet {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    border-bottom: 1px solid setColor('ui', 'border-color');
    max-width: 500px;
    padding-bottom: .5rem;

    &__body {
      display: flex;
      flex-direction: column;
      flex-basis: calc(100% - 45px);
      max-width: calc(100% - 45px);
    }

    &__pic {
      height: 35px;
      min-width: 35px;
      max-width: 35px;
      border-radius: 50%;
      border: 1px solid setColor('ui', 'border-color');
      overflow: hidden;
      margin-right: .5em;
      position: relative;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

    }

    &__infos {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-size: .875rem;

      &-title {
        font-weight: 700;
        display: inline-block;
        margin-bottom: .35rem;
      }

      &-price {
        opacity: .6;
        min-width: 100px;
        margin-left: 0.5rem;
        text-align: right;
        span {
          opacity: 1;
          font-weight: 600;
          color: setColor('neutral', 'n02');
        }
      }
    }

    &__sub {
      color: setColor('ui', 'text-placeholder');
      font-size: .875em;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      gap: .25em;

      &-duration {
        b {
          color: setColor('ui', 'text-dark');
        }
      }

      &-departure {
        text-align: right;
        b {
          color: setColor('ui', 'text-dark');
        }
      }
    }

    &__price {
      color: setColor('ui', 'text-dark');
      font-weight: 700;
    }

    &__footer {
      flex-basis: 78%;
      max-width: 78%;
      margin-top: 0.75em;
    }

    &__show-more {
      font-size: .875em;
      color: setColor('theme', 't01');
      padding: 0 .5em;
    }

    &__items {
      max-height: 0;
      transition: max-height 0.11s ease-out;
      overflow: hidden;
      padding: .5em 1em;

      &--opened {
        max-height: 1000vh;
        transition: transform 0.11s ease-in;
      }

      &-cta {
        text-align: right;
        font-size: 14px;
        margin: .5em 0 0;

        transform: translatex(0) scale(1) translatez(0);
        transition: transform 0.11s ease-in;

        .icon {
          color: setColor('theme', 't01');
          font-size: 12px;
          margin-left: .25em;
          text-decoration: none;
        }

        .label {
          color: setColor('theme', 't01');
          text-decoration: underline;
          cursor: pointer;
        }

        &:hover {

          transform: translatex(.25em) scale(1) translatez(0);
          transition: transform 0.11s ease-in;

          .icon {
            text-decoration: none;
          }
        }
      }
    }
  }
}
