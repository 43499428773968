.c-transports {
  padding: 0 1rem;
  &__container {
    margin: 2rem 0;
  }
  &__checkbox {
    label {
      font-size: .9125rem;
      cursor: pointer;
      i {
        vertical-align: middle;
      }
      .form-check-input:checked {
        background-color: $brand-color-01;
        border-color: $brand-color-01;
      }
    }
  }
  &__message {
    font-size: .75rem;
    opacity: .7;
  }
  &__list {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin-top: 1.25rem;
    gap: 1rem;
    padding: 0 1rem;
  }
  /*&__item {
    flex: 0 0 100%;
    cursor: pointer;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 0 0 48.7998%;
    }
    @media (min-width: map-get($grid-breakpoints, xl)) {
      flex: 0 0 32.2998%;
    }
    i {
      color: setColor('theme', 't01');
    }
  }*/
  &__item {
    margin-bottom: 1rem;
    margin-right: 1rem;
    border: 1px solid $brand-color-01;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    color: $brand-color-01;
    font-weight: 500;
    cursor: pointer;
    &.active {
      color: #fff;
      background-color: $brand-color-01;
    }
    &.disabled {
      &:not(.active) {
        pointer-events: none;
        opacity: .75;
        border-color: #ccc;
        color: #ccc;
        cursor: default;
      }
    }
  }
}
