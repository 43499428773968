:root {
  --gutter: .5rem;
  --margin: -.5rem;
}
.container-fluid {
  width: 100%;
  max-width: 100vw;
}

.container-xl {
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 820px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 1180px;
  }
  @media (min-width: 1600px) {
    max-width: 1440px;
  }
}
.container-lg {
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 820px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 880px;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    max-width: 960px;
  }
  @media (min-width: 1600px) {
    max-width: 1280px;
  }
}
.container-sm {
  @media (min-width: 1600px) {
    max-width: 1180px;
  }
}

// Questo sistema il padding e i margini di bootstrap secondo il modello Conad
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {

  // overflow-x: hidden;
  // max-width: 375px;
  padding-right: calc(var(--gutter) * 2);
  padding-left: calc(var(--gutter) * 2);
  .row {
    max-width: 100vw;
    margin-left: var(--margin);
    margin-right: var(--margin);

    >.col,
    >[class*="col-"] {
      padding-right: var(--gutter);
      padding-left: var(--gutter);
    }
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    // max-width: 752px;
    padding-right: var(--gutter);
    padding-left: var(--gutter);
    // margin: 0 48px;

    .row {
      margin-left: var(--margin);
      margin-right: var(--margin);

      >.col,
      >[class*="col-"] {
        padding-right: var(--gutter);
        padding-left: var(--gutter);
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, xl)) {
    // max-width: 1344px;
    padding-right: calc(var(--gutter) + calc(var(--gutter) /2 ));
    padding-left: calc(var(--gutter) + calc(var(--gutter) /2 ));
    // margin: 0 48px;

    .row {
      margin-left: calc(var(--margin) + calc(var(--margin) /2 ));
      margin-right: calc(var(--margin) + calc(var(--margin) /2 ));

      >.col,
      >[class*="col-"] {
        padding-right: calc(var(--gutter) + calc(var(--gutter) /2 ));
        padding-left: calc(var(--gutter) + calc(var(--gutter) /2 ));
      }
    }
  }
}
