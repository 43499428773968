@font-face {
  font-family: 'oto-icons-mini';
  src:
    url('../../assets/fonts/oto-icons-mini.ttf?zd0x16') format('truetype'),
    url('../../assets/fonts/oto-icons-mini.woff?zd0x16') format('woff'),
    url('../../assets/fonts/oto-icons-mini.svg?zd0x16#oto-icons-mini') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="oto-icon-"], [class*=" oto-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'oto-icons-mini' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oto-icon-facebook:before {
  content: "\e936";
}
.oto-icon-instagram:before {
  content: "\e937";
}
.oto-icon-linkedin:before {
  content: "\e938";
}
.oto-icon-twitter:before {
  content: "\e939";
}
.oto-icon-vimeo:before {
  content: "\e93a";
}
.oto-icon-whatsapp:before {
  content: "\e93b";
}
.oto-icon-youtube:before {
  content: "\e93c";
}
.oto-icon-trash:before {
  content: "\e94a";
}
.oto-icon-help:before {
  content: "\e949";
}
.oto-icon-gallery:before {
  content: "\e93f";
}
.oto-icon-confirmed1:before {
  content: "\e934";
}
.oto-icon-full:before {
  content: "\e93d";
}
.oto-icon-pending:before {
  content: "\e93e";
}
.oto-icon-height:before {
  content: "\e940";
}
.oto-icon-decks:before {
  content: "\e941";
}
.oto-icon-star:before {
  content: "\e942";
}
.oto-icon-team:before {
  content: "\e943";
}
.oto-icon-weight:before {
  content: "\e944";
}
.oto-icon-width:before {
  content: "\e945";
}
.oto-icon-chevron-left-circle:before {
  content: "\e935";
}
.oto-icon-chevron-top-circle:before {
  content: "\e946";
}
.oto-icon-chevron-bottom-circle:before {
  content: "\e947";
}
.oto-icon-chevron-right-circle:before {
  content: "\e948";
}
.icon-check:before {
  content: "\e933";
}
.oto-icon-close-circled:before {
  content: "\e932";
}
.oto-icon-date:before {
  content: "\e931";
}
.oto-icon-tour:before {
  content: "\e92f";
}
.oto-icon-holiday:before {
  content: "\e930";
}
.oto-icon-duration:before {
  content: "\e92e";
}
.oto-icon-age:before {
  content: "\e900";
}
.oto-icon-anchor:before {
  content: "\e901";
}
.oto-icon-arrow-left:before {
  content: "\e902";
}
.oto-icon-arrow-right:before {
  content: "\e903";
}
.oto-icon-bed:before {
  content: "\e904";
}
.oto-icon-bus:before {
  content: "\e905";
}
.oto-icon-cabins:before {
  content: "\e906";
}
.oto-icon-cancellation:before {
  content: "\e907";
}
.oto-icon-check-off:before {
  content: "\e908";
}
.oto-icon-check-on:before {
  content: "\e909";
}
.oto-icon-chevron-left:before {
  content: "\e90a";
}
.oto-icon-chevron-right:before {
  content: "\e92c";
}
.oto-icon-chevron-up:before {
  content: "\e90b";
}
.oto-icon-chevron-down:before {
  content: "\e92d";
}
.oto-icon-confirmed:before {
  content: "\e90c";
}
.oto-icon-cruise-itinerary:before {
  content: "\e90d";
}
.oto-icon-cruise-ship:before {
  content: "\e90e";
}
.oto-icon-fire:before {
  content: "\e90f";
}
.oto-icon-free:before {
  content: "\e910";
}
.oto-icon-group:before {
  content: "\e911";
}
.oto-icon-helm:before {
  content: "\e912";
}
.oto-icon-diff:before {
  content: "\e913";
}
.oto-icon-img:before {
  content: "\e914";
}
.oto-icon-languages:before {
  content: "\e915";
}
.oto-icon-phone:before {
  content: "\e916";
}
.oto-icon-pin:before {
  content: "\e917";
}
.oto-icon-shield:before {
  content: "\e918";
}
.oto-icon-tratment:before {
  content: "\e919";
}
.oto-icon-type:before {
  content: "\e91a";
}
.oto-icon-info-rounded:before {
  content: "\e91b";
}
.oto-icon-landing-alt:before {
  content: "\e91c";
}
.oto-icon-landing:before {
  content: "\e91d";
}
.oto-icon-lock:before {
  content: "\e91e";
}
.oto-icon-minus-inverse:before {
  content: "\e91f";
}
.oto-icon-plane-no:before {
  content: "\e920";
}
.oto-icon-plane:before {
  content: "\e921";
}
.oto-icon-plus-inverse:before {
  content: "\e922";
}
.oto-icon-radio-off:before {
  content: "\e923";
}
.oto-icon-radio-on:before {
  content: "\e924";
}
.oto-icon-search:before {
  content: "\e925";
}
.oto-icon-service:before {
  content: "\e926";
}
.oto-icon-ship:before {
  content: "\e927";
}
.oto-icon-show-more-eye:before {
  content: "\e928";
}
.oto-icon-show-more-text:before {
  content: "\e929";
}
.oto-icon-take-off:before {
  content: "\e92a";
}
.oto-icon-traveler:before {
  content: "\e92b";
}
