.c-experience {
  &-meeting-points {
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 1rem;
    @media (min-width: 992px) {
      flex-direction: row;
    }
    li {
      flex: 1;
      padding-right: 1rem;
      margin-bottom: 2rem;
      @media (min-width: 992px) {
        flex: 0 0 50%;
      }
      @media (min-width: 1440px) {
        flex: 0 0 33%;
      }
    }
    span {
      font-size: .875rem;
      opacity: .6;
      color: setColor('ui', 'text-dark');
    }
    h6 {
      margin: 1rem 0 .75rem;
      font-weight: 400;
    }
    a {
      font-size: .875rem;
      i {
        font-size: .75rem;
      }
    }
  }
  &-calendar {
    display: flex;
    flex-direction: column;
    background-color: setColor('neutral', 'n01');
    box-shadow: map-get($card, box-shadow);
    border-radius: map-get($card, border-radius);
    padding: 2rem;
    margin-top: 1rem;
    @media (min-width: 992px) {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &__calendar, &__passengers {
      flex: 1;
      @media (min-width: 992px) {
        flex: 0 0 45%;
      }
    }
    &__total {
      flex: 1 0 100%;
      text-align: right;
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid setColor('ui', 'border-color');
      span {
        display: block;
        margin-bottom: .65rem;
        vertical-align: middle;
        small {
          font-size: .75rem;
        }
        span {
          display: inline-block;
          margin-left: .5rem;
          margin-bottom: 0;
          font-size: 1.375rem;
          font-weight: 700;
        }
      }
    }
    &__passengers {
      &-count {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid setColor('ui', 'border-color');
        .title {
          font-size: .875rem;
          color: setColor('ui', 'text-dark');
          opacity: .6;
          margin-bottom: 0;
        }
      }
      &-select {
        .form-row {
          .form-group-custom {
            padding-right: 0;
          }
        }
      }
    }
  }
}
