@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "src/global/styles/configurations";

.c-filters {
  background-color: #fff;
  padding: 1rem;
  box-shadow: map-get($card, box-shadow);

  &__header {
    border-bottom: 1px solid setColor('dark', 'darken-20');
    margin: 0;
    @media (min-width: map-get($grid-breakpoints, md)) {
      border-bottom: 0;
    }
    h3.h3 {
      padding: map-get($spacers, 3) map-get($spacers, 3) map-get($spacers, 3) map-get($spacers, 2);
      margin: 0;
      @media (min-width: map-get($grid-breakpoints, md)) {
        padding: 0
      }
    }
    .o-icon {
      padding-right: map-get($spacers, 3);
    }
  }

  &__check {
    .form-check-custom {
      &__label {
        font-weight: 400;
        font-size: .9125rem;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: map-get($spacers, 2);
    border-top: 1px solid setColor('dark', 'darken-80');
    gap: 1rem;
    background-color: setColor('light', 'lighten-100');
    z-index: 1;
    @media (min-width: map-get($grid-breakpoints, md)) {
      display: none;
    }
    .indicator {
      display: inline-block;
      padding: 0 .25rem;
    }
    .c-button {
      width: 100%;
    }
  }
  &-type {
    margin-bottom: .5rem;
    &__title {
      button {
        padding: .5rem 0;
        font-weight: 600;
        line-height: 1;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }
}
