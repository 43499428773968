@import "src/global/styles/configurations";

.o-section {
  padding: 3rem 0;
  &-detail-header {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    @media (min-width: 992px) {
      margin-bottom: 1rem;
      margin-top: 0;
    }

    &__main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (min-width: 992px) {
        justify-content: flex-start;
      }
    }

    &__logo {

      &__outer {
        position: absolute;
        top: 2.5rem;
        left: 1.5rem;
        z-index: 1;
        @media (min-width: 576px) {
          position: relative;
          top: unset;
          left: unset;
          flex: 0 1 auto;
          margin-right: 2rem;
        }
      }

      &__inner {
        width: 3.5rem;
        height: 3.5rem;
        background-size: contain;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #fff;
        @media (min-width: 768px) {
          width: 4.5rem;
          height: 4.5rem;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__accomodation {
      font-size: 1.25rem;
      line-height: 1.5rem;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: .5rem;
      @media (min-width: 768px) {
        font-size: 1.35rem;
        line-height: 1.5rem;
        font-weight: 700;
        margin-bottom: 0.25rem;
      }
    }

    &__stars {
      color: #FFB400;
      margin-left: .5rem;
      i{
        font-size: .9125em;
        vertical-align: top;
        line-height: inherit;
      }
    }

    &__destination {
      line-height: 1.25rem;
      color: setColor('neutral', 't02');
      opacity: .7;
      font-size: .9125rem;
    }

    &__price {
      font-size: 1.75rem;
      line-height: 1.25rem;
      i {
        font-size: 1rem;
        font-weight: normal;
      }
    }

    &--fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      background-color: #F8FAFB;
    }

  }
}
.o-section-detail {
  display: flex;
  flex-flow: column wrap;
  gap: 1rem;
  margin-top: 0;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  padding-top: 0;
  border-bottom: 1px dashed setColor('ui', 'border-color');

  @media (min-width: map-get($grid-breakpoints, lg)) {
    flex-flow: row wrap;
  }

  &__title {
    flex: 0 0 100%;
    position: relative;
    max-width: 100%;
    font-weight: 700;
    font-size: 20px;
    color: setColor('ui', 'text-color');
    letter-spacing: 0;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex: 0 0 calc(15% - 1rem);
      max-width: calc(15% - 1rem);
    }
  }

  &__body {
    flex: 0 0 100%;
    position: relative;
    max-width: 100%;
    @media (min-width: map-get($grid-breakpoints, lg)) {
      max-width: calc(85% - 1rem);
      flex: 0 0 calc(85% - 1rem);
    }

    &--detail-result {
      min-height: unset;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        min-height: 400px;
      }
    }
  }

  &__engine {
    background-color: setColor('theme', 't01');
    border-radius: 1rem;
    padding: 1.5rem;
    border: 1px solid setColor('ui', 'border-color');
    border-top: 0;
    box-shadow: map-get($card, box-shadow);
  }

}

.o-section-body {
  padding-bottom: 2rem;
}
