@import "src/global/styles/configurations";

.o-page {
  background: #F8FAFB;
}

.page-generic {
  p, ul li, ol li {
    font-size: .875rem;
  }
  ul, ol {
    li {
      opacity: .7;
      padding: .35rem 0;
      line-height: 1.7;
    }
  }
}
