.c-passengers-counter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;

  &__btn {
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    vertical-align: center;
    color: #fff;
    background-color: setColor('theme', 't01');
    text-align: center;
    font-size: .65rem;
    line-height: 3.2;
    border-radius: map-get($input, border-radius);
    //border: 1px solid setColor('theme', 't01');
    width: 30px;
    height: 30px;
    &--disabled {
      opacity: .5;
      cursor: default;
    }

    i {
      color: setColor('neutral', 'n01');
      &:after, &:before {
        font-weight: 900;
      }
    }
  }

  &__count {
    pointer-events: none;
    font-weight: 600;
    font-size: 1.5rem;
    color: setColor('ui', 'text-dark');
    display: inline-block;
    vertical-align: top;
    line-height: 30px;
    padding: 0 2px;
    text-align: center;
    border: 0;
    margin: 0;
    width: 15%;
    background-color: transparent;
  }
}

.c-passengers-number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .c-passengers-counter {
    max-width: 150px;
  }
  span {
    font-family: 'Poppins', sans-serif;
  }
}
