// Our function for shorter usage of map-get();
@function setColor($scheme, $tone: base) {
    @return map-get(map-get($colorscheme, $scheme), $tone);
}

// Usage: setColor('semantic', 's01');

$brand-color-01: #005B9A;
$brand-color-02: #57ADDE;
$brand-color-03: #FBA610;
$brand-color-04: #999BAB;
// Scheme of colors
$colorscheme: (

  theme: (
    t01: #0061a0,
    t02: #FBA610,
    t03: #F9817C,
    t04: #999BAB
  ),

  semantic: (
    success: #00A652,
    error: #E2332B,
    warning: #ffb602,
    info: #12B2E6,
    disabled: rgba(0, 0, 0, 0.05)
  ),

  neutral: (
    n01: #fff,
    n02: #000
  ),

  utilies: (
    u01: #34BB27,
    u02: #F37121,
    u03: #AAA0E5,
    u04: #009DE2,
    u05: #19AF39
  ),

  dark: (
    darken-0: rgba(0, 0, 0, 0),
    darken-5: rgba(0, 0, 0, 0.05),
    darken-10: rgba(0, 0, 0, 0.1),
    darken-20: rgba(0, 0, 0, 0.2),
    darken-30: rgba(0, 0, 0, 0.3),
    darken-40: rgba(0, 0, 0, 0.4),
    darken-60: rgba(0, 0, 0, 0.6),
    darken-80: rgba(0, 0, 0, 0.8),
    darken-90: rgba(0, 0, 0, 0.9),
    darken-100: rgba(0, 0, 0, 1),
  ),

  light: (
    lighten-0: rgba(255, 255, 255, 0),
    lighten-5: rgba(255, 255, 255, 0.05),
    lighten-10: rgba(255, 255, 255, 0.1),
    lighten-20: rgba(255, 255, 255, 0.2),
    lighten-30: rgba(255, 255, 255, 0.3),
    lighten-40: rgba(255, 255, 255, 0.4),
    lighten-60: rgba(255, 255, 255, 0.6),
    lighten-80: rgba(255, 255, 255, 0.8),
    lighten-90: rgba(255, 255, 255, 0.9),
    lighten-100: rgba(255, 255, 255, 1),
  ),

  brand-grades: (
    darken: darken($brand-color-01, 20) !important,
    lighten: lighten($brand-color-01, 39) !important,
    brand-dark: #002947,
  ),

  ui: (
    background: #fff,
    background-ico: #F4F4F4,
    border-color: #ddd,
    box-shadow: #343434,
    text-dark: #343434,
    text-placeholder: lighten(#343434, 32)
  ),

  uiColors: (
    u01: #5a6872,
    u02: #888,
    u03: #ebebeb,
    u04: #f6f6f6,
    u05: #f9f9fc,
  ),

  forms: (
    border: #b3b3b3,
    focus: rgba(198, 225, 252, .25),
    filled: #333333,
    disabled: rgba(0, 0, 0, 0.05),
  ),

  dividers: (
    subtle: rgba(0, 0, 0, 0.2),
    strong: #e20714,
    inverse: #f8f6ef
  ),

  icons: (
    enabled: #333333,
    active: #e20714,
    sub: rgba(0, 0, 0, 0.3),
    inverse: #fed404,
    selected: #faced0
  ),

  btnDirect: (
    normal: $brand-color-03,
    hover: darken($brand-color-03, 70),
    disable: rgba(0, 0, 0, 0.05)
  ),

  btnSecondary: (
    normal: $brand-color-01,
    hover: darken($brand-color-01, 70),
    disable: rgba(0, 0, 0, 0.05)
  ),

  btnAccent: (
    normal: #008F8C,
    hover: darken(#008F8C, 70),
    disable: rgba(0, 0, 0, 0.05)
  ),

  btnInverse: (
    normal: rgba(0, 0, 0, 0.8),
    hover: rgba(0, 0, 0, 0.91),
    disabled: rgba(0, 0, 0, 0.05)
  )

);
