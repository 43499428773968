.o-accordion {
  &__link {
    background-color: setColor('theme', 't01');
    color: setColor('neutral', 'n01');
    width: 100%;
    border: 0;
    border-radius: .5rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 700;
    span {
      text-align: left;
    }
    .place {
      flex: 1 0 35%;
    }
    .date {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .cta {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  &__body {
    background-color: setColor('neutral', 'n01');
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    padding: 1.8rem;
    box-shadow: map-get($card, box-shadow);
  }
}
