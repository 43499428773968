.u-text {
  &--center {
    text-align: center !important;
  }
  &--right {
    text-align: right !important;
  }
  &--left {
    text-align: left !important;
  }
  &--light {
    font-weight: map-get($font-weights, light) !important;
  }
  &--regular {
    font-weight: map-get($font-weights, regular) !important;
  }
  &--medium {
    font-weight: map-get($font-weights, medium) !important;
  }
  &--bold {
    font-weight: map-get($font-weights, bold) !important;
  }
  &--uppercase {
    text-transform: uppercase;
  }
  &--underline {
    text-decoration: underline !important;
  }
  &--primary {
    color: setColor('theme', 't01') !important;
  }
  &--accent {
    color: setColor('theme', 't02') !important;
  }
  &--white {
    color: setColor('neutral', 'n01') !important;
  }
  &--link {
    text-decoration: underline;
    color: setColor('theme', 't01') !important;
    small {
      color: setColor('theme', 't01') !important;
    }
  }
  &--ellipsis-2 {
    @include multiLineEllipsis($lineHeight: 1.2rem, $lineCount: 2, $bgColor: transparent);
  }
  &--ellipsis-3 {
    @include multiLineEllipsis($lineHeight: 1.5rem, $lineCount: 3, $bgColor: transparent);
  }
}

a {
  color: inherit !important;
  text-decoration: none !important;
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}
