/* _forms.scss */
input[type="password"],
input[type="text"],
input[type="file"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="tel"],
textarea,
select {
  padding: 0.63rem 0.75rem; //0.63rem 2.4rem 0.63rem 0.75rem;
  font-size: 1rem; /* 16px */
  color: setColor('uiColors', 'u01');
  background-color: #fff;
  background-image: none;
  border: 0.0625rem /* 1px */ solid setColor('ui', 'border-color');
  box-shadow: none;
  border-radius: map-get($input, border-radius);
  @media (min-width: 997px) {
    font-size: 0.875rem; /* 14px */
  }

  &::placeholder {
    color: setColor('ui', 'text-placeholder');
  }

  &[disabled] {
    background-color: #f7f8fa !important;
    cursor: default !important;
    opacity: .7;
    pointer-events: none;
    &:hover {
      border-color: #ccc;
    }
  }
}

select {
  line-height: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  /*font-family: 'Roboto Mono', monospace;*/
}

@import '_form-group';
@import '_form-control';
@import '_form-check';
@import '_input-group';
@import '_input-dropdown';
/* _forms.scss END*/
