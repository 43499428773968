@import "src/global/styles/configurations";

.u-label-choice {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: flex-start;
  &__item {
    margin-right: 1rem;
    label {
      width: 206px;
      height: 126px;
      border-radius: map-get($card, border-radius);
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      margin-bottom: 0;
      i {
        font-size: 2rem;
        color: setColor('theme', 't01');
        display: block;
        margin-bottom: .5rem;
      }
      .form-check-custom {
        &__label {
          font-weight: 400;
          text-align: center;
          padding-left: 0;
        }
        &__radio, &__check {
          top: .5rem;
          left: .5rem;
          visibility: hidden;
        }
      }
      &:hover {
        .form-check-custom__radio, .form-check-custom__check {
          visibility: visible;
          transition: visibility ease .5s;
        }
      }
      &.is-active {
        box-shadow: map-get($card, box-shadow);
        border: 1px solid setColor('theme', 't01');
        .form-check-custom__radio, .form-check-custom__check {
          visibility: visible;
        }
      }
    }
  }
}
