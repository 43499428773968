.o-header {
  &.navbar {
    padding: 1rem 0;
    .navbar-brand {
      .logo {
        max-width: 250px;
        height: auto;
      }
    }
  }
  &--top {
    background-color: setColor('theme', 't01');
  }
  &__row {
    display: flex;
    color: setColor('neutral', 'n01');
    ul {
      display: flex;
      flex-direction: row;
      margin: .75rem 0;
    }
  }
}
