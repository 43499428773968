@import "src/global/styles/configurations";

.c-search-cta {
  background-color: #fff;
  box-shadow: 0 -1px 11px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: space-around;
  font-size: 1.5rem;
  color: #888;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    display: none;
  }
  &__icon {
    cursor: pointer;
    padding: 1rem 3rem;
    &.disabled {
      cursor: not-allowed;
    }
  }
  .active {
    background-color: transparentize(setColor('theme', 't01'), 0.5);
    color: setColor('theme', 't01');
  }
  .disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }
}
