.c-date-pagination {
  padding-bottom: 30px;

  .c-date-pagination_bar {
    margin: 0;
    padding: 0;


    &-link {
      display: flex;
      flex-direction: column;
      height: 60px;
      color: setColor('ui', 'text-dark');
      font-weight: 600;
      font-size: 15px;
      min-width: 32px;
      line-height: 32px;
      text-align: center;
      padding: 0;
      border-color: setColor('ui', 'border-color');
      margin-left: -1px;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }

      // &:not([aria-label="Previous"]):not([aria-label="Next"]) {
      //   border-color: rgba(17, 29, 85, .4);
      // }

      @media (min-width: 992px) {
        font-size: 18px;
      }

      &:hover {
        border-color: #E9EAF0 #e8ecf0 #E9EAF0 #e8ecf0;
      }

      &-day {
        font-size: 0.9rem;
        margin-bottom: 0;
        flex-basis: 26px;
        max-height: 26px;
        font-weight: 400;
        color: #000;
      }

      &-date {
        display: flex;
        justify-content: center;
        flex-basis: 34px;
        max-height: 34px;
        color: #000;

        &-number {
          font-size: 2rem;
          line-height: 25px;
          flex-basis: 55px;
          max-width: 55px;
          font-weight: 700;
        }

        &-month {
          display: flex;
          flex-direction: column;
          font-size: 0.65rem;
          font-weight: 400;
          flex-basis: 35px;
          max-width: 35px;
          text-transform: capitalize;
          text-align: left;

          span {
            line-height: 15px;
            flex-basis: 15px;
            max-height: 15px;
          }
        }
      }

      &:hover {
        background: setColor('ui', 'background');
        color: #ffffff;
        outline: 0;
      }

      &:focus {
        outline: 0;
      }
    }

    &-item {
      position: relative;
      width: 26%;
      padding-left: 0;
      margin-left: 0;

      @media (min-width: 992px) {
        width: 20%;
      }

      &:first-child,
      &:last-child {
        width: 11%;

        .date-pagination_bar-link {
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        @media (min-width: 992px) {
          width: 8%;
        }

        span {
          i {
            display: inline-block;
            font-size: 1.5rem;
            line-height: 2;
            color: setColor('theme', 't01');
          }
        }
      }

      &.active {
        .page-link {
          background: setColor('theme', 't01');
          border-color: setColor('theme', 't01');
          color: #ffffff;
          cursor: default;
          z-index: 1;
          span {
            color: #ffffff;
          }
        }
      }

      &.disabled {
        .page-link {
          cursor: default;
          span {
            color: setColor('ui', 'text-placeholder');
            opacity: .6;
          }
        }
      }

      &:not(.disabled) {
        .page-link {
           span {
             color: setColor('ui', 'text-dark');
           }
        }
      }
    }
  }
}
