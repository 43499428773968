@import "src/global/styles/configurations";

.o-engine {
  position: relative;
  z-index: 3;
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, .15);
  border-radius: 8px;

  /*@media (min-width: 992px) {
    position: absolute;
    bottom: -5rem;
    left: 0;
    right: 0;
    border-radius: 5px;
  }*/

  &-container {
    margin-top: -59px;
  }

  &__pills {
    &.nav {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      background: setColor('theme', 't01');
      border-radius: 8px 8px 0 0;
      text-align: center;
      margin-left: 0;
      position: relative;
      margin-bottom: 0;
      z-index: 2;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
      scroll-padding: 0 50%;
    }

    &-tab {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
      font-weight: 400;
      padding: 1.5rem;
      position: relative;
      text-decoration: none;
      line-height: 1;
      z-index: 1;
      cursor: pointer;
      margin-right: -1px;
      transition: margin-bottom .33s;
      margin-bottom: 0;
      flex: 1 0 auto;
      color: setColor('neutral', 'n01');
      border-radius: 8px 8px 0 0;

      @media (min-width: 992px) {
        flex-flow: row nowrap;
        padding: 1.25rem;
        flex: 0 0 auto;
      }

      i {
        font-size: 14px;
        margin-right: 8px;
        @media (min-width: 992px) {
          font-size: 1.125rem;
        }
      }

      span,
      i {
        color: setColor('neutral', 'n01');
      }
      &--contoured {
        border-top: 1px solid setColor('theme', 't02');
        border-bottom: 0;
        border-radius: 0;
        span,
        i {
          color: setColor('theme', 't02');
        }
        &:first-of-type {
          border-top-left-radius: 8px;
          border-left: 1px solid setColor('theme', 't02');
        }
        &:nth-child(4) {
          border-top-right-radius: 8px;
          border-right: 1px solid setColor('theme', 't02');
        }
      }

      &.active {
        position: relative;
        z-index: 2;
        margin-bottom: -1px;
        transition: margin-bottom .22s;
        &:before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          background-color: setColor('neutral', 'n01');
          z-index: -1;
        }
        span,
        i {
          color: setColor('theme', 't01');
        }
      }

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }

    &-label {
      font-size: .875rem;
      letter-spacing: .3px;
      font-weight: 700;
      display: block;
      position: relative;

      @media (min-width: 992px) {
        display: inline-block;
        margin-top: 0;
      }
      span {
        font-size: .6rem;
        position: absolute;
        top: -.7rem;
        left: 0;
        width: max-content;
        font-weight: 300;
      }
    }
    &--sticky {
      display: flex;
      flex: 1 auto;
    }
    &--nav {

    }
  }

  &__container {
    //position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1050;
    @media (min-width: 992px) {
      position: relative;
    }
  }

  &__content {
    // position: relative;
    // z-index: 1;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 0 0 8px 8px;
    border-top: 0;
    box-shadow: map-get($card, box-shadow);
  }
}
