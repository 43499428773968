.c-list-column {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 1rem;
  @media (min-width: 992px) {
    flex-direction: row;
  }
  li {
    flex: 1;
    padding-right: 1rem;
    margin-bottom: 2rem;
    @media (min-width: 992px) {
      flex: 0 0 33%;
    }
  }
  span {
    font-size: .875rem;
    opacity: .6;
    color: setColor('ui', 'text-dark');
    line-height: 24px;
    display: block;
    margin-bottom: 1rem;
  }
  h4 {
    margin: 1rem 0 .75rem;
    font-weight: 600;
    font-size: 1.125rem;
    i {
      display: inline-block;
      margin-right: .5rem;
    }
  }
  a {
    font-size: .875rem;
    i {
      font-size: .75rem;
    }
  }
}
